import React, { useState } from "react";

import { Box, Button, TextField, Paper } from "@mui/material";

const PaymentCommentsBox = (props) => {
    const {
        updateCaseComments,
        textFieldStyle,
        comments,
        errors,
        handleClose,
        setComments,
        setErrors
    } = props;

    return (
        <Paper elevation={10}>
            <Paper sx={{ backgroundColor: "steelblue", color: "white", mb: "2vh", p: ".5vh", textAlign: 'center', height: '4vh' }}>
                <h4 style={{ fontWeight: 'bold', fontSize: 'inherit' }}>Payment Comments</h4>
            </Paper>
            <Box sx={{ width: "95%", m: "2vh auto" }}>
                <form onSubmit={(e) => updateCaseComments(e)}>
                    <Box>
                        <TextField
                            sx={textFieldStyle}
                            multiline
                            rows={11}
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                            onBlur={(e) => {
                                if (!e.target.value) {
                                    setErrors({ ...errors, comments: "required" })
                                } else {
                                    setErrors({ ...errors, comments: null })
                                }
                            }}
                            fullWidth
                            label="Comments"
                            variant="outlined"
                            error={Boolean(errors.comments)}
                            helperText={errors.comments}
                            inputProps={{ maxLength: 255 }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", gap: "1rem", justifyContent: "right", pb: '1vh' }}>
                        <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white", mt: "1vh" }} type="submit">
                            Save Comments
                        </Button>
                        <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white", mt: "1vh", ml: "4px" }} onClick={handleClose}>
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Box>
        </Paper>
    );
};

export default PaymentCommentsBox;
