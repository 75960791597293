import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';

import { Box, MenuItem, LinearProgress, TextField, Button, Paper, Checkbox, FormControlLabel } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { ThemeModeContext, apiRoute } from '../../../App';
import { Filters, GLFilters, PaymentTypeOptions, formattedDate, formatTime } from '../../../Utils';
import { UserContext } from '../../../context/UserContext';

const ReportsDateRangeModal = (props) => {
    const { handleClose, reportPath, selectedReport, agencyCode, glPostingJournalFilter, setGlPostingJournalFilter } = props;

    const { mode } = useContext(ThemeModeContext);
    const { users } = useContext(UserContext);

    const [calendarOpen, setCalendarOpen] = useState(false);
    const currentDate = new Date();
    const formattedDefaultDate = currentDate.toISOString().split('T')[0]; // Extract date part
    const [startDate, setStartDate] = useState(formattedDefaultDate);
    const [endDate, setEndDate] = useState(formattedDefaultDate);
    const [filterOption, setFilterOption] = useState('NONE');
    const [issueAgency, setIssueAgency] = useState('none');
    const [paymentType, setPaymentType] = useState('none');
    const [loadingReport, setLoadingReport] = useState(false);
    const [errors, setErrors] = useState({ startDate: '', endDate: '' });
    const [caseNum, setCaseNum] = useState("");
    const [shiftClerkId, setShiftClerkId] = useState("");
    const [bondStatus, setBondStatus] = useState('NONE');
    const [masterReceipt, setMasterReceipt] = useState("");
    const [desotoStatusFilter, setDesotoStatusFilter] = useState("NONE");
    const [includeRejected, setIncludeRejected] = useState(false);
    const [dateRangeSelected, setDateRangeSelected] = useState(selectedReport === "GeneralLedger" ? true : false);
    const [caseNumberSelected, setCaseNumberSelected] = useState(true);
    const [receiptNumberSelected, setReceiptNumberSelected] = useState(false);
    const [casePaymentFilter, setCasePaymentFilter] = useState("Case Number");
    const [excludeTimeServed, setExcludeTimeServed] = useState(true);
    const [excludeRefunds, setExcludeRefunds] = useState(true);
    const [disburseSelected, setDisburseSelected] = useState(false);
    const [depositNumSelected, setDepositNumSelected] = useState(false);
    const [disburseDatesList, setDisburseDatesList] = useState([]);
    const [depositList, setDepositList] = useState([]);
    const [disburseDate, setDisburseDate] = useState("");
    const [deposit, setDeposit] = useState({}); // refactor to deposit set to {}
    const [previousDate, setPreviousDate] = useState(false);
    const [glFilter, setGlFilter] = useState('NONE');

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === 'dark' ? '#4C4E52' : 'white',
            color: mode === 'dark' ? 'white' : 'black',
        },
        '& .MuiInputLabel-root': {
            color: mode === 'dark' ? 'white' : 'black',
            textshadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    };

    const handleDateIconClick = () => {
        setCalendarOpen(!calendarOpen);
    };

    const handleDateInputClick = () => {
        setCalendarOpen(!calendarOpen);
    };

    const getDepositList = () => {
        setLoadingReport(true);
        axios.get(`${apiRoute}/api/depositTbls`)
            .then((res) => {
                console.log("List of Deposits for current entity", res.data)
                setDepositList(res.data)
            })
            .catch((err) => {
                console.error("error getting deposits", err)
            })
            .finally(() => setLoadingReport(false))
    }

    const getDisburseDates = () => {
        setLoadingReport(true);
        axios.get(`${apiRoute}/api/DisburseReportViews`)
            .then((res) => {
                const dateRows = res.data.map((row, i) => {
                    return {
                        id: `${row.disburseDate}-${i}`,
                        Date: formattedDate(row.disburseDate),
                        Time: formatTime(row.disburseDate),
                        EnteredBy: row.enteredBy,
                        Value: row.disburseDate
                    }
                })
                setDisburseDatesList(dateRows);
            })
            .catch((err) => {
                console.error("error getting disburse dates", err);
                console.log('This is done')
            })
            .finally(() => setLoadingReport(false))
    };

    const handleGenerateReportClick = () => {
        const pdfReportResponseHandler = (response) => {
            const arrayBufferView = new Uint8Array(response.data);
            const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
            const dataUrl = URL.createObjectURL(blob);
            setLoadingReport(false);
            handleClose();
            window.open(dataUrl, '_blank');
        };
        if (startDate > endDate) {
            setErrors({
                startDate: "Starting date cannot come after ending date",
                endDate: "Ending date cannot come before starting date"
            });
            return;
        }
        setErrors({ startDate: null, endDate: null });
        setLoadingReport(true);
        if (selectedReport === 'GeneralLedger') { // modify depositNum to deposit.Id/num
            let GeneralLedgerRoute = disburseSelected ? `${apiRoute}/${reportPath}?startDateRange=${"9999-12-29 20:00:00.000"}&endDateRange=${"9999-12-29 20:00:00.000"}&disburseDate=${disburseDate}` :
                depositNumSelected ? `${apiRoute}/${reportPath}?startDateRange=${"9999-12-29 20:00:00.000"}&endDateRange=${"9999-12-29 20:00:00.000"}&depositId=${deposit.pkDepositId}&depositNumber=${deposit.depositNumber}&disburseDate=${"9999-12-29 20:00:00.000"}` :
                    `${apiRoute}/${reportPath}?startDateRange=${startDate}&endDateRange=${endDate}&disburseDate=${"9999-12-29 20:00:00.000"}`
            axios
                .get(
                    GeneralLedgerRoute,
                    {
                        responseType: 'arraybuffer',
                    }
                )
                .then((response) => {
                    pdfReportResponseHandler(response);
                })
                .catch((error) => {
                    console.error('Error fetching report from front end:', error);
                });
        } else if (selectedReport === 'TypeBasedPayment') {
            axios
                .get(
                    `${apiRoute}/${reportPath}?startDateRange=${startDate}&endDateRange=${endDate}&paymentType=${paymentType === 'none' ? '' : paymentType
                    }&excludeTS=${excludeTimeServed}&excludeRefunds=${excludeRefunds}`,
                    {
                        responseType: 'arraybuffer',
                    }
                )
                .then((response) => {
                    pdfReportResponseHandler(response);
                })
                .catch((error) => {
                    console.error('Error fetching report from front end:', error);
                });
        } else if (selectedReport === 'BatchTransactionDetail') {
            axios
                .get(
                    `${apiRoute}/${reportPath}?startDateRange=${startDate}&endDateRange=${endDate}`,
                    {
                        responseType: 'arraybuffer',
                    }
                )
                .then((response) => {
                    pdfReportResponseHandler(response);
                })
                .catch((error) => {
                    console.error('Error fetching report from front end:', error);
                });
        } else if (selectedReport === 'CasePayment') {
            axios
                .get(
                    `${apiRoute}/${reportPath}startDateRange=${startDate}&endDateRange=${endDate}&caseNum=${caseNum === '' ? 'NONE' : caseNum}&masterReceipt=${masterReceipt === "" ? "NONE" : masterReceipt}&excludeTimeServed=${excludeTimeServed}`,
                    { responseType: 'arraybuffer' }
                )
                .then((response) => {
                    pdfReportResponseHandler(response);
                })
                .catch((error) => {
                    setLoadingReport(false)
                    handleClose();
                    console.error('Error fetching report from front end:', error);
                });
        } else if (selectedReport === 'RefundReport') {
            axios
                .get(
                    `${apiRoute}/${reportPath}?startDateRange=${startDate}&endDateRange=${endDate}&shiftClerkId=${shiftClerkId === '' ? 'NONE' : shiftClerkId}`,
                    { responseType: 'arraybuffer' }
                )
                .then((response) => {
                    pdfReportResponseHandler(response);
                })
                .catch((error) => {
                    setLoadingReport(false)
                    handleClose();
                    console.error('Error fetching report from front end:', error);
                });
        } else if (selectedReport === 'CashBondTransactions') {
            axios
                .get(
                    `${apiRoute}/${reportPath}?startDateRange=${startDate}&endDateRange=${endDate}&filterOption=${bondStatus}`,
                    { responseType: 'arraybuffer' }
                )
                .then((response) => {
                    pdfReportResponseHandler(response);
                })
                .catch((error) => {
                    setLoadingReport(false)
                    handleClose();
                    console.error('Error fetching report from front end:', error);
                });
        } else if (selectedReport === 'UnapprovedPayments') {
            axios
                .get(
                    `${apiRoute}/${reportPath}startDateRange=${startDate}&endDateRange=${endDate}&statusFilter=${desotoStatusFilter}`,
                    { responseType: 'arraybuffer' }
                )
                .then((response) => {
                    pdfReportResponseHandler(response);
                })
                .catch((error) => {
                    console.error('Error fetching report from front end:', error);
                });
        } else if (selectedReport === 'ReconcileReportDesoto') {
            axios
                .get(
                    `${apiRoute}/${reportPath}startDateRange=${startDate}&endDateRange=${endDate}&includeRejected=${includeRejected}`,
                    { responseType: 'arraybuffer' }
                )
                .then((response) => {
                    pdfReportResponseHandler(response);
                })
                .catch((error) => {
                    console.error('Error fetching report from front end:', error);
                });
        } else {
            axios
                .get(
                    `${apiRoute}/${reportPath}startDateRange=${startDate}&endDateRange=${endDate}&filterOption=${filterOption}`,
                    { responseType: 'arraybuffer' }
                )
                .then((response) => {
                    pdfReportResponseHandler(response);
                })
                .catch((error) => {
                    console.error('Error fetching report from front end:', error);
                });
        }
    };

    const handleExtract = () => {
        if (startDate > endDate) {
            setErrors({
                startDate: "Starting date cannot come after ending date",
                endDate: "Ending date cannot come before starting date"
            });
            return;
        }
        setErrors({ startDate: null, endDate: null });
        setLoadingReport(true);
        return axios.get(`${apiRoute}/${reportPath}/${startDate}/${endDate}`, {
            responseType: 'blob',
            headers: {
                'Accept': 'text/csv',
            },
        })
            .then(response => {
                const blob = new Blob([response.data], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `${selectedReport}${startDate}-${endDate}.csv`

                // Append the link to the document and trigger the download
                document.body.appendChild(a);
                a.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                setLoadingReport(false);
                handleClose();
            })
            .catch(error => {
                console.log(error)
            });
    };

    const handleGlExtract = (filter) => {
        if (startDate > endDate) {
            setErrors({
                startDate: "Starting date cannot come after ending date",
                endDate: "Ending date cannot come before starting date"
            });
            return;
        }
        setErrors({ startDate: null, endDate: null });
        setLoadingReport(true);
        return axios.get(`${apiRoute}/${reportPath}/${startDate}/${endDate}/${filter}`, {
            responseType: 'blob',
            headers: {
                'Accept': 'text/csv',
            },
        })
            .then(response => {
                const blob = new Blob([response.data], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `${selectedReport}${startDate}-${endDate}.csv`

                // Append the link to the document and trigger the download
                document.body.appendChild(a);
                a.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                setLoadingReport(false);
                handleClose();
            })
            .catch(error => {
                console.log(error)
            });
    };

    // Flag to show Filter By DropDown
    const isDisplayFilterBy =
        selectedReport === 'DepositExtract' ||
        selectedReport === 'PaymentRegister' ||
        selectedReport === 'DistributionCheckExtract' ||
        selectedReport === 'DistributionReport';

    const handleBlur = (input) => {
        if (startDate > endDate) {
            setErrors(prevErrors => ({ ...prevErrors, [input]: `${input === 'startDate' ? 'Start' : 'End'} date cannot come ${input === 'startDate' ? 'after ending date' : 'before starting date'}` }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, startDate: null, endDate: null }));
        }
    };

    useEffect(() => {
        if (selectedReport === "GeneralLedger") {
            getDisburseDates();
            getDepositList();
        }
    }, [selectedReport])

    return (
        <Paper elevation={10}>
            <Paper sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                <h1 style={{ fontWeight: 'bold' }}>
                    {
                        selectedReport === 'DepositExtract'
                            ? 'Deposit Extract Dates'
                            : selectedReport === 'DistributionCheckExtract'
                                ? 'Check Extract Dates'
                                : selectedReport === 'GlTransactionExtract'
                                    ? 'Gl Transaction Dates'
                                    : selectedReport === 'GeneralLedger'
                                        ? 'General Ledger'
                                        : 'Report Dates'
                    }
                </h1>
            </Paper>
            <Box p={1} sx={{ textAlign: 'center' }}>
                <h5 style={{ marginY: '1vh' }}>
                    Select a date range
                </h5>
                <hr />
                {selectedReport !== "CasePayment" && selectedReport !== "GeneralLedger" && <>
                    <TextField
                        sx={{ ...textFieldStyle, mb: '4vh', width: '75%' }}
                        type="date"
                        id="startDateRange"
                        label="Start Date"
                        value={startDate}
                        onChange={(e) => {
                            setStartDate(e.target.value);
                            handleDateInputClick();
                            handleDateIconClick();
                        }}
                        onClick={handleDateInputClick}
                        onBlur={() => handleBlur('startDate')}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled={loadingReport}
                        helperText={errors?.startDate}
                        error={!!errors.startDate}
                    />
                    <TextField
                        sx={{ ...textFieldStyle, width: '75%' }}
                        type="date"
                        id="endDateRange"
                        label="End Date"
                        value={endDate}
                        onChange={(e) => {
                            setEndDate(e.target.value);
                            handleDateInputClick();
                            handleDateIconClick();
                        }}
                        onClick={handleDateInputClick}
                        onBlur={() => handleBlur('endDate')}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled={loadingReport}
                        helperText={errors?.endDate}
                        error={!!errors.endDate}
                    />
                </>}
                {selectedReport === "CasePayment" &&
                    <TextField
                        sx={{ ...textFieldStyle, width: "10vw" }}
                        select
                        label="Filter By"
                        value={casePaymentFilter}
                        onChange={(e) => { setCasePaymentFilter(e.target.value); }}
                    >
                        <MenuItem
                            key='caseNumSelect'
                            value={"Case Number"}
                            onClick={(e) => { setCaseNumberSelected(true); setDateRangeSelected(false); setReceiptNumberSelected(false) }}
                        >
                            Case Number
                        </MenuItem>
                        <MenuItem
                            key='daterangeselect'
                            value={"Date Range"}
                            onClick={(e) => { setDateRangeSelected(true); setCaseNumberSelected(false); setReceiptNumberSelected(false) }}
                        >
                            Date Range
                        </MenuItem>
                        <MenuItem
                            key='receiptnumselect'
                            value={"Receipt Number"}
                            onClick={(e) => { setReceiptNumberSelected(true); setCaseNumberSelected(false); setDateRangeSelected(false) }}
                        >
                            Receipt Number
                        </MenuItem>
                    </TextField>}
                {isDisplayFilterBy && (
                    selectedReport === 'DistributionCheckExtract' || selectedReport === 'DepositExtract' ?
                        <TextField
                            sx={{ ...textFieldStyle, width: '75%', display: 'none', mt: '4vh' }}
                            id="filterBy"
                            select
                            label="Filter By"
                            defaultValue="NONE"
                            value={filterOption}
                            onChange={(e) => setFilterOption(e.target.value)}
                        >
                            {Filters?.map(({ value, label }) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </TextField> :
                        <TextField
                            sx={{ ...textFieldStyle, width: '75%', mt: '4vh' }}
                            id="filterBy"
                            select
                            label="Filter By"
                            defaultValue="NONE"
                            value={filterOption}
                            onChange={(e) => setFilterOption(e.target.value)}
                        >
                            {Filters?.map(({ value, label }) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </TextField>
                )}
                {selectedReport === 'GeneralLedger' && (
                    <TextField
                        sx={{ ...textFieldStyle, width: '50%', mb: '2vh' }}
                        id="glfilterby"
                        select
                        label="Filter By"
                        value={glPostingJournalFilter}
                        onChange={(e) => setGlPostingJournalFilter(e.target.value)}
                    >
                        <MenuItem
                            key='daterangeselect'
                            value={"DateRange"}
                            onClick={() => { setDateRangeSelected(true); setDepositNumSelected(false); setDisburseSelected(false); }}
                        >
                            Date Range
                        </MenuItem>
                        <MenuItem
                            key='depositnumselect'
                            value={"DepositNum"}
                            onClick={() => { setDepositNumSelected(true); setDateRangeSelected(false); setDisburseSelected(false); }}
                        >
                            Deposit Number
                        </MenuItem>
                        <MenuItem
                            key='disbursedateselect'
                            value={"DisburseDate"}
                            onClick={() => { setDisburseSelected(true); setDepositNumSelected(false); setDateRangeSelected(false); }}
                        >
                            Disbursement Date
                        </MenuItem>
                    </TextField>
                )}
                {selectedReport === 'GeneralLedger' &&
                    <>
                        {disburseSelected && <DataGrid
                            columns={[
                                {
                                    field: "Date",
                                    headerName: "Disburse Date",
                                    width: 175
                                },
                                {
                                    field: "Time",
                                    headerName: "Disburse Time",
                                    width: 175
                                },
                                {
                                    field: "EnteredBy",
                                    headerName: "Disbursed By",
                                    width: 250
                                }
                            ]}
                            rows={disburseDatesList}
                            loading={loadingReport}
                            onCellDoubleClick={handleGenerateReportClick}
                            autoHeight={false}
                        onRowClick={(currentRow) => {
                            setDisburseDate(currentRow.row.Value);
                                const currentIndex = disburseDatesList.findIndex((row) => row.id === currentRow.id);
                                setPreviousDate(disburseDatesList[currentIndex + 1]?.Value)
                            }}
                            localeText={{ noRowsLabel: "No Disbursement Dates Found" }}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 25 } }
                            }}
                            sx={{
                                ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                                    "marginTop": "1em",
                                    "marginBottom": "1em"
                                },

                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                    outline: "none !important",
                                }, "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                                    outline: "none !important",
                                },
                            }}
                        />}
                        {depositNumSelected && <TextField // set value to deposit,
                            sx={{ ...textFieldStyle, width: '75%', mt: '2vh' }}
                            select
                            id="depositNum"
                            label="Deposit Number"
                            value={deposit.depositNumber}
                            onChange={(e) => setDeposit(e.target.value)}
                        >
                            {
                                depositList.filter((deposit) => {
                                    return deposit.depositNumber !== null
                                }).map((deposit) => {
                                    return (
                                        <MenuItem key={deposit.depositNumber} value={deposit}>
                                            {deposit.depositNumber}
                                        </MenuItem>
                                    )
                                })
                            }
                        </TextField>}
                    </>


                }
                {
                    selectedReport === 'GlTransactionExtract' && (
                        <TextField
                            sx={{ ...textFieldStyle, width: '75%', mt: '4vh' }}
                            id="filterBy"
                            select
                            label="Filter By"
                            defaultValue="NONE"
                            value={glFilter}
                            onChange={(e) => setGlFilter(e.target.value)}
                        >
                            {GLFilters?.map(({ value, label }) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </TextField>
                    )
                }
                {/*{selectedReport === 'GeneralLedger' && (*/}
                {/*    <TextField*/}
                {/*        sx={{ ...textFieldStyle, width: '75%', mt: '4vh' }}*/}
                {/*        id="issueAgency"*/}
                {/*        select*/}
                {/*        label="Issue Agency"*/}
                {/*        defaultValue="none"*/}
                {/*        value={issueAgency}*/}
                {/*        onChange={(e) => setIssueAgency(e.target.value)}*/}
                {/*    >*/}
                {/*        <MenuItem value={'none'}>N/A</MenuItem>*/}
                {/*        {agencyCode?.map(({ code, description }) => (*/}
                {/*            <MenuItem key={code} value={code}>*/}
                {/*                {description}*/}
                {/*            </MenuItem>*/}
                {/*        ))}*/}
                {/*    </TextField>*/}
                {/*)}*/}
                {selectedReport === 'TypeBasedPayment' && (
                    <>
                        <TextField
                            sx={{ ...textFieldStyle, width: '75%', mt: '4vh' }}
                            id="paymentType"
                            select
                            label="Payment Type"
                            defaultValue="none"
                            value={paymentType}
                            onChange={(e) => setPaymentType(e.target.value)}
                        >
                            {PaymentTypeOptions?.map(({ value, label }) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Box sx={{ display: 'flex', flexDirection: "column", ml: '3vw' }}>
                            <FormControlLabel sx={{ mt: '2vh' }}
                                control={<Checkbox />}
                                label="Exclude Time Served"
                                checked={excludeTimeServed}
                                onChange={() => setExcludeTimeServed(!excludeTimeServed)}
                            />
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Exclude Refunds"
                                checked={excludeRefunds}
                                onChange={() => setExcludeRefunds(!excludeRefunds)}
                            />
                        </Box>
                    </>
                )}
                {selectedReport === 'BatchCollectionByDateRange' && (
                    <TextField
                        sx={{ ...textFieldStyle, width: '75%', mt: '4vh' }}
                        id="filterby"
                        select
                        label="Filter by"
                        defaultValue="NONE"
                        value={filterOption}
                        onChange={(e) => setFilterOption(e.target.value)}
                    >
                        {Filters?.map(({ value, label }) => (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>
                )}
                {selectedReport === 'RefundReport' && (
                    <TextField
                        sx={{ ...textFieldStyle, width: '75%', mt: '4vh' }}
                        id="filterby"
                        select
                        label="shift clerk"
                        defaultValue=""
                        value={shiftClerkId}
                        onChange={(e) => setShiftClerkId(e.target.value)}
                    >
                        {users?.map(({ name, id }) => (
                            <MenuItem key={id} value={id}>
                                {name}
                            </MenuItem>
                        )
                        )}
                    </TextField>
                )}
                {dateRangeSelected && <> <TextField
                    sx={{ ...textFieldStyle, mb: '4vh', width: '75%', mt: '2vh' }}
                    type="date"
                    id="startDateRange"
                    label="Start Date"
                    value={startDate}
                    onChange={(e) => {
                        setStartDate(e.target.value);
                        handleDateInputClick();
                        handleDateIconClick();
                    }}
                    onClick={handleDateInputClick}
                    onBlur={() => handleBlur('startDate')}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    disabled={loadingReport}
                    helperText={errors?.startDate}
                    error={!!errors.startDate}
                />
                    <TextField
                        sx={{ ...textFieldStyle, width: '75%' }}
                        type="date"
                        id="endDateRange"
                        label="End Date"
                        value={endDate}
                        onChange={(e) => {
                            setEndDate(e.target.value);
                            handleDateInputClick();
                            handleDateIconClick();
                        }}
                        onClick={handleDateInputClick}
                        onBlur={() => handleBlur('endDate')}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled={loadingReport}
                        helperText={errors?.endDate}
                        error={!!errors.endDate}
                    />
                </>}
                {selectedReport === 'CasePayment' && (
                    <>
                        {caseNumberSelected && <TextField
                            sx={{ ...textFieldStyle, width: '75%', mt: '2vh' }}
                            id="casenumber"
                            label="Case Number"
                            value={caseNum}
                            onChange={(e) => { setCaseNum(e.target.value); }}
                        />}
                        {receiptNumberSelected && <TextField
                            sx={{ ...textFieldStyle, width: '75%', mt: '2vh' }}
                            id="receiptnum"
                            label="Receipt Number"
                            value={masterReceipt}
                            onChange={(e) => { setMasterReceipt(e.target.value); }}
                        />}
                        <FormControlLabel sx={{ mt: '2vh' }}
                            control={<Checkbox />}
                            label="Exclude Time Served"
                            checked={excludeTimeServed}
                            onChange={() => setExcludeTimeServed(!excludeTimeServed)}
                        />
                    </>
                )}
                {selectedReport === 'CashBondTransactions' && (
                    <TextField
                        sx={{ ...textFieldStyle, width: '75%', mt: '4vh' }}
                        id="bondstatus"
                        select
                        label="Status"
                        defaultValue="NONE"
                        value={bondStatus}
                        onChange={(e) => setBondStatus(e.target.value)}
                    >
                        <MenuItem key={'none'} value={'NONE'}>
                            None
                        </MenuItem>
                        <MenuItem key={'disbursed'} value={'APPLIED'}>
                            Applied
                        </MenuItem>
                        <MenuItem key={'held'} value={'POSTED'}>
                            Posted
                        </MenuItem>
                        <MenuItem key={'refunded'} value={'REFUNDED'}>
                            Refunded
                        </MenuItem>

                    </TextField>
                )}
                {selectedReport === 'UnapprovedPayments' && (
                    <TextField
                        sx={{ ...textFieldStyle, width: '75%', mt: '4vh' }}
                        id="desotostatus"
                        select
                        label="Status"
                        defaultValue="NONE"
                        value={desotoStatusFilter}
                        onChange={(e) => setDesotoStatusFilter(e.target.value)}
                    >
                        <MenuItem key={'none'} value={'NONE'}>
                            None
                        </MenuItem>
                        <MenuItem key={'rejected'} value={'Rejected'}>
                            Rejected
                        </MenuItem>
                        <MenuItem key={'new'} value={'New'}>
                            New
                        </MenuItem>
                        <MenuItem key={'pending'} value={'Pending'}>
                            Pending
                        </MenuItem>
                    </TextField>
                )}
                {selectedReport === 'ReconcileReportDesoto' && (
                    <FormControlLabel sx={{ mt: '2vh' }}
                        control={<Checkbox />}
                        label="Include Rejected Payments"
                        checked={includeRejected}
                        onChange={() => setIncludeRejected(!includeRejected)}
                    />

                )}
                <hr />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', mt: '3vh', mb: '1vh' }}>
                    {!selectedReport.includes('Extract')
                        ?
                        <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} disabled={loadingReport} onClick={handleGenerateReportClick}>
                            Generate Report
                        </Button>
                        :
                        <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} disabled={loadingReport} onClick={() => {
                            return selectedReport === 'GlTransactionExtract' ? handleGlExtract(glFilter) : handleExtract()
                        }}>
                            Generate CSV Extract
                        </Button>
                    }
                    <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} disabled={loadingReport} onClick={handleClose}>
                        Cancel
                    </Button>
                </Box>
            </Box>
            {loadingReport && (
                <LinearProgress sx={{ width: '99.5%', m: '0 auto' }} />
            )}
        </Paper>
    );
};

export default ReportsDateRangeModal;
