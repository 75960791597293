import React, { useContext, useState } from 'react';
import axios from 'axios';

import { Box, Grid, Button, MenuItem, TextField, Paper, Tooltip } from '@mui/material'

import { toast } from 'react-toastify';

import * as yup from 'yup';
import { withFormik } from 'formik';

import { CaseContext } from '../../../../context/CaseContext';
import { apiRoute } from '../../../../App.js';
import { SentencingValidations } from '../../../../schemas/SentencingSchema.js';
import { commaMoneyFormat } from '../../../../Utils';


const Sentencing = (props) => {
    const {
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        handleClose,
        ts,
    } = props;

    const { agencyCode } = useContext(CaseContext);

    const [displayFineAmt, setDisplayFineAmt] = useState(commaMoneyFormat(values?.fineAmount))
    const [displaySusFine, setDisplaySusFine] = useState(commaMoneyFormat(values?.suspendedFineAmount))
    const [displayCostAmt, setDisplayCostAmt] = useState(commaMoneyFormat(values?.costAmount))
    const [displaySusCost, setDisplaySusCost] = useState(commaMoneyFormat(values?.suspendedCostAmount))

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Box sx={{ display: "flex", gap: "2rem", mt: '1.5vh' }}>
                    <Paper sx={{ width: "95%", margin: 'auto' }} elevation={10}>
                        <Paper sx={{ backgroundColor: 'steelblue', color: 'white', height: '3vh', m: 'auto', p: '.5vh' }}>
                            <h6>
                                <strong>Sentencing</strong>
                            </h6>
                        </Paper>
                        <Box sx={{ width: "95%", margin: "1vh auto" }}>
                            <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", gap: "1rem" }}>
                                <Grid item sm={2.5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        type="date"
                                        id="sentenceDate"
                                        label="Sentence Date"
                                        name="sentenceDate"
                                        value={values?.sentenceDate}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helpertext={touched.sentenceDate ? errors.sentenceDate : ""}
                                        error={touched.sentenceDate && Boolean(errors.sentenceDate)}
                                    />
                                </Grid>
                                <Grid item sm={2.5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        type="date"
                                        id="dispositionDate"
                                        label="Disposition Date"
                                        name="dispositionDate"
                                        value={values?.dispositionDate}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.dispositionDate ? errors.dispositionDate : ""}
                                        error={touched.dispositionDate && Boolean(errors.dispositionDate)}
                                    />
                                </Grid>
                                <Grid item sm={2}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="finalDisposition"
                                        label="Final Disposition"
                                        name="finalDisposition"
                                        select
                                        defaultValue={""}
                                        value={values?.finalDisposition}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.finalDisposition ? errors.finalDisposition : ""}
                                        error={touched.finalDisposition && Boolean(errors.finalDisposition)}
                                    >
                                        <MenuItem value="">Clear Selection</MenuItem>
                                        <MenuItem value="Guilty">Guilty</MenuItem>
                                        <MenuItem value="Not Guilty">Not Guilty</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item sm={2}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        select
                                        id="sentencingAgency"
                                        label="Agency"
                                        name="sentencingAgency"
                                        value={values?.sentencingAgency}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.sentencingAgency ? errors.sentencingAgency : ""}
                                        error={touched.speedLimit && Boolean(errors.sentencingAgency)}
                                    >
                                        <MenuItem value={null || '' || undefined}> </MenuItem>
                                        {
                                            agencyCode?.map(({ code }) => {
                                                return <MenuItem key={code} value={code}>{code}</MenuItem>
                                            })
                                        }</TextField>
                                </Grid>
                                <Grid item sm={2}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        type="date"
                                        id="startDate"
                                        label="Start Date"
                                        name="startDate"
                                        defaultValue={values?.sentenceStartDate}
                                        value={values?.startDate}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.sentenceStartDate ? errors.sentenceStartDate : ""}
                                        error={touched.sentenceStartDate && Boolean(errors.sentenceStartDate)}
                                    />
                                </Grid>
                                <Grid item sm={2.5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="fineAmount"
                                        label="Fine Amount"
                                        name="fineAmount"
                                        value={displayFineAmt}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setDisplayFineAmt(e.target.value);
                                        }}
                                        onBlur={(e) => {
                                            if (e.target.value) {
                                                setDisplayFineAmt(commaMoneyFormat(e.target.value))
                                            } else {
                                                setDisplayFineAmt(commaMoneyFormat(0))
                                            }
                                        }}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.fineAmount ? errors.fineAmount : ""}
                                        error={touched.fineAmount && Boolean(errors.fineAmount)}
                                    />
                                </Grid>
                                <Grid item sm={2.5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="suspendedFineAmount"
                                        label="Suspended Fine"
                                        name="suspendedFineAmount"
                                        value={displaySusFine}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setDisplaySusFine(e.target.value);
                                        }}
                                        onBlur={(e) => {
                                            if (e.target.value) {
                                                setDisplaySusFine(commaMoneyFormat(e.target.value))
                                            } else {
                                                setDisplaySusFine(commaMoneyFormat(0))
                                            }
                                        }}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.suspendedFineAmount ? errors.suspendedFineAmount : ""}
                                        error={touched.suspendedFineAmount && Boolean(errors.suspendedFineAmount)}
                                    />
                                </Grid>
                                <Grid item sm={2.5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="costAmount"
                                        label="Cost Amount"
                                        name="costAmount"
                                        value={displayCostAmt}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setDisplayCostAmt(e.target.value);
                                        }}
                                        onBlur={(e) => {
                                            if (e.target.value) {
                                                setDisplayCostAmt(commaMoneyFormat(e.target.value))
                                            } else {
                                                setDisplayCostAmt(commaMoneyFormat(0))
                                            }
                                        }}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.costAmount ? errors.costAmount : ""}
                                        error={touched.costAmount && Boolean(errors.costAmount)}
                                    />
                                </Grid>
                                <Grid item sm={2.5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="suspendedCostAmount"
                                        label="Suspended Cost"
                                        name="suspendedCostAmount"
                                        value={displaySusCost}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setDisplaySusCost(e.target.value);
                                        }}
                                        onBlur={(e) => {
                                            if (e.target.value) {
                                                setDisplaySusCost(commaMoneyFormat(e.target.value))
                                            } else {
                                                setDisplaySusCost(commaMoneyFormat(0))
                                            }
                                        }}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.suspendedCostAmount ? errors.suspendedCostAmount : ""}
                                        error={touched.suspendedCostAmount && Boolean(errors.suspendedCostAmount)}
                                    />
                                </Grid>
                                <Grid item sm={1.5}>
                                    <Tooltip title="Days In Lieu">
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            id="daysInLieu"
                                            label="DIL"
                                            name="daysInLieu"
                                            value={values?.daysInLieu}
                                            onChange={handleChange}
                                            variant='outlined'
                                            InputLabelProps={{ shrink: true }}
                                            helperText={touched.daysInLieu ? errors.daysInLieu : ''}
                                            error={touched.daysInLieu && Boolean(errors.daysInLieu)}
                                        /></Tooltip>
                                </Grid>
                                <Grid item sm={1.5}>
                                    <Tooltip title="Time Served">
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            id="timeServed"
                                            label="TS"
                                            name="timeServed"
                                            value={values?.timeServed}
                                            onChange={handleChange}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            helperText={touched.timeServed ? errors.timeServed : ''}
                                            error={touched.timeServed && Boolean(errors.timeServed)}
                                        /></Tooltip>
                                </Grid>
                                <Grid item sm={1.8}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="yearsSentenced"
                                        label="Years"
                                        name="yearsSentenced"
                                        value={values?.yearsSentenced}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.yearsSentenced ? errors.yearsSentenced : ""}
                                        error={touched.yearsSentenced && Boolean(errors.yearsSentenced)}
                                    />
                                </Grid>
                                <Grid item sm={1.8}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="monthsSentenced"
                                        label="Months"
                                        name="monthsSentenced"
                                        value={values?.monthsSentenced}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.monthsSentenced ? errors.monthsSentenced : ""}
                                        error={touched.monthsSentenced && Boolean(errors.monthsSentenced)}
                                    />
                                </Grid>
                                <Grid item sm={1.8}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="daysSentenced"
                                        label="Days"
                                        name="daysSentenced"
                                        value={values?.daysSentenced}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.daysSentenced ? errors.daysSentenced : ""}
                                        error={touched.daysSentenced && Boolean(errors.daysSentenced)}
                                    />
                                </Grid>
                                <Grid item sm={1.8}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="hoursSentenced"
                                        label="Hours"
                                        name="hoursSentenced"
                                        value={values?.hoursSentenced}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.hoursSentenced ? errors.hoursSentenced : ""}
                                        error={touched.hoursSentenced && Boolean(errors.hoursSentenced)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
                <Box sx={{ width: '95%', display: "flex", gap: "2rem", margin: '2vh auto' }}>
                    <Paper sx={{ width: "35%" }} elevation={10}>
                        <Paper sx={{ backgroundColor: 'steelblue', color: 'white', height: '3vh', m: 'auto', p: '.5vh' }}>
                            <h6>
                                <strong>Suspended Sentence</strong>
                            </h6>
                        </Paper>
                        <Box sx={{ width: "95%", margin: "1vh auto" }}>
                            <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", gap: "1rem", ml: '.5vw' }}>
                                <Grid item sm={5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="yearsSuspended"
                                        label="Years"
                                        name="yearsSuspended"
                                        value={values?.yearsSuspended}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.yearsSuspended ? errors.yearsSuspended : ""}
                                        error={touched.yearsSuspended && Boolean(errors.yearsSuspended)}
                                    />
                                </Grid>
                                <Grid item sm={5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="monthsSuspended"
                                        label="Months"
                                        name="monthsSuspended"
                                        value={values?.monthsSuspended}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.monthsSuspended ? errors.monthsSuspended : ""}
                                        error={touched.monthsSuspended && Boolean(errors.monthsSuspended)}
                                    />
                                </Grid>
                                <Grid item sm={5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="daysSuspended"
                                        label="Days"
                                        name="daysSuspended"
                                        value={values?.daysSuspended}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.daysSuspended ? errors.daysSuspended : ""}
                                        error={touched.daysSuspended && Boolean(errors.daysSuspended)}
                                    />
                                </Grid>
                                <Grid item sm={5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="hoursSuspended"
                                        label="Hours"
                                        name="hoursSuspended"
                                        value={values?.hoursSuspended}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.hoursSuspended ? errors.hoursSuspended : ""}
                                        error={touched.hoursSuspended && Boolean(errors.hoursSuspended)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                    <Paper sx={{ width: "35%" }} elevation={10}>
                        <Paper sx={{ backgroundColor: 'steelblue', color: 'white', height: '3vh', m: 'auto', p: '.5vh' }}>
                            <h6>
                                <strong>Probation</strong>
                            </h6>
                        </Paper>
                        <Box sx={{ width: "95%", margin: "1vh auto" }}>
                            <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", gap: "1rem", ml: '.5vw' }}>
                                <Grid item sm={5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="yearsProbation"
                                        label="Years"
                                        name="yearsProbation"
                                        value={values?.yearsProbation}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.yearsProbation ? errors.yearsProbation : ""}
                                        error={touched.yearsProbation && Boolean(errors.yearsProbation)}
                                    />
                                </Grid>
                                <Grid item sm={5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="monthsProbation"
                                        label="Months"
                                        name="monthsProbation"
                                        value={values?.monthsProbation}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.monthsProbation ? errors.monthsProbationt : ""}
                                        error={touched.monthsProbation && Boolean(errors.monthsProbation)}
                                    />
                                </Grid>
                                <Grid item sm={5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="daysProbation"
                                        label="Days"
                                        name="daysProbation"
                                        value={values?.daysProbation}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.daysProbation ? errors.daysProbation : ""}
                                        error={touched.daysProbation && Boolean(errors.daysProbation)}
                                    />
                                </Grid>
                                <Grid item sm={5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="hoursProbation"
                                        label="Hours"
                                        name="hoursProbation"
                                        value={values?.hoursProbation}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.hoursProbation ? errors.hoursProbation : ""}
                                        error={touched.hoursProbation && Boolean(errors.hoursProbation)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                    <Paper sx={{ width: "35%" }} elevation={10}>
                        <Paper sx={{ backgroundColor: 'steelblue', color: 'white', height: '3vh', m: 'auto', p: '.5vh' }}>
                            <h6>
                                <strong>Community Service</strong>
                            </h6>
                        </Paper>
                        <Box sx={{ width: "95%", margin: "1vh auto" }}>
                            <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", gap: "1rem", ml: '.5vw' }}>
                                <Grid item sm={5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="yearsCommService"
                                        label="Years"
                                        name="yearsCommService"
                                        value={values?.yearsCommService}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.yearsCommService ? errors.yearsCommService : ""}
                                        error={touched.yearsCommService && Boolean(errors.yearsCommService)}
                                    />
                                </Grid>
                                <Grid item sm={5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="monthsCommService"
                                        label="Months"
                                        name="monthsCommService"
                                        value={values?.monthsCommService}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.monthsCommService ? errors.monthsCommService : ""}
                                        error={touched.monthsCommService && Boolean(errors.monthsCommService)}
                                    />
                                </Grid>
                                <Grid item sm={5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="daysCommService"
                                        label="Days"
                                        name="daysCommService"
                                        value={values?.daysCommService}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.daysCommService ? errors.daysCommService : ""}
                                        error={touched.daysCommService && Boolean(errors.daysCommService)}
                                    />
                                </Grid>
                                <Grid item sm={5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="hoursCommService"
                                        label="Hours"
                                        name="hoursCommService"
                                        value={values?.hoursCommService}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={touched.hoursCommService ? errors.hoursCommService : ""}
                                        error={touched.hoursCommService && Boolean(errors.hoursCommService)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "right", gap: '1rem' }}>
                    <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} type="submit">Save Sentence</Button>
                    <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={handleClose}>Close</Button>
                </Box>
            </form>
        </>
    )
}

const Form = withFormik({
    mapPropsToValues: (props) => {
        const sentenceDetail = props.violationData;
        if (!sentenceDetail) return {};
        const {
            daysInLieu,
            timeServed,
            sentenceDate,
            dispositionDate,
            finalDisposition,
            sentencingAgency,
            sentenceStartDate,
            fineAmount,
            suspendedFineAmount,
            costAmount,
            suspendedCostAmount,
            yearsSentenced,
            monthsSentenced,
            daysSentenced,
            hoursSentenced,
            yearsSuspended,
            monthsSuspended,
            daysSuspended,
            hoursSuspended,
            yearsProbation,
            monthsProbation,
            daysProbation,
            hoursProbation,
            yearsCommService,
            monthsCommService,
            daysCommService,
            hoursCommService,
        } = sentenceDetail;
        return {
            daysInLieu: daysInLieu || '0',
            timeServed: timeServed || '0',
            sentenceDate: sentenceDate ? new Date(sentenceDate).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10),
            dispositionDate: dispositionDate ? new Date(dispositionDate).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10),
            finalDisposition: finalDisposition === 'Y' ? 'Guilty' : 'Not Guilty',
            sentencingAgency: sentencingAgency || '',
            startDate: sentenceStartDate ? new Date(sentenceStartDate).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10),
            fineAmount: typeof fineAmount === 'number' ? fineAmount.toFixed(2) : '0.00',
            suspendedFineAmount: typeof suspendedFineAmount === 'number' ? suspendedFineAmount.toFixed(2) : '0.00',
            costAmount: typeof costAmount === 'number' ? costAmount.toFixed(2) : '0.00',
            suspendedCostAmount: typeof suspendedCostAmount === 'number' ? suspendedCostAmount.toFixed(2) : '0.00',
            yearsSentenced: yearsSentenced || '0',
            monthsSentenced: monthsSentenced || '0',
            daysSentenced: daysSentenced || '0',
            hoursSentenced: hoursSentenced || '0',
            yearsSuspended: yearsSuspended || '0',
            monthsSuspended: monthsSuspended || '0',
            daysSuspended: daysSuspended || '0',
            hoursSuspended: hoursSuspended || '0',
            yearsProbation: yearsProbation || '0',
            monthsProbation: monthsProbation || '0',
            daysProbation: daysProbation || '0',
            hoursProbation: hoursProbation || '0',
            yearsCommService: yearsCommService || '0',
            monthsCommService: monthsCommService || '0',
            daysCommService: daysCommService || '0',
            hoursCommService: hoursCommService || '0',
        }
    },

    validationSchema: yup.object().shape(SentencingValidations),

    formatMoney: (value) => {
        return value.toFixed(2).toLocaleString();
    },

    handleSubmit: (values, props) => {
        const { violationData, handleClose } = props.props;
        const sentencingData = {
            yearsSentenced: +values?.yearsSentenced,
            monthsSentenced: +values?.monthsSentenced,
            daysSentenced: +values?.daysSentenced,
            hoursSentenced: +values?.hoursSentenced,
            sentenceStartDate: values?.startDate || new Date().toISOString().substr(0, 10),
            fineAmount: parseFloat(values?.fineAmount.replace(/[^0-9.-]/g, '')),
            suspendedFineAmount: parseFloat(values?.suspendedFineAmount.replace(/[^0-9.-]/g, '')),
            costAmount: parseFloat(values?.costAmount.replace(/[^0-9.-]/g, '')),
            suspendedCostAmount: parseFloat(values?.suspendedCostAmount.replace(/[^0-9.-]/g, '')),
            yearsSuspended: +values?.yearsSuspended,
            monthsSuspended: +values?.monthsSuspended,
            daysSuspended: +values?.daysSuspended,
            hoursSuspended: +values?.hoursSuspended,
            yearsProbation: +values?.yearsProbation,
            monthsProbation: +values?.monthsProbation,
            daysProbation: +values?.daysProbation,
            hoursProbation: +values?.hoursProbation,
            yearsCommService: +values?.yearsCommService,
            monthsCommService: +values?.monthsCommService,
            daysCommService: +values?.daysCommService,
            hoursCommService: +values?.hoursCommService,
            daysInLieu: values?.daysInLieu || 0,
            timeServed: values?.timeServed || 0,
            sentenceDate: values?.sentenceDate || new Date().toISOString().substr(0, 10),
            dispositionDate: values?.dispositionDate || new Date().toISOString().substr(0, 10),
            finalDisposition: values?.finalDisposition === "Guilty" ? "Y" : values?.finalDisposition === "Not Guilty" ? "N" : 'N',
            sentencingAgency: values?.sentencingAgency,
        }

        const violationId = violationData.pkViolationId

        axios.patch(`${apiRoute}/api/ViolationTbls/PostSentence/${violationId}`, sentencingData)
            .then((res) => {
                toast.success('Sentencing Details Posted Successfully', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                handleClose();
            }).catch((err) => {
                console.log("ADD SENTENCE DATA❌", err);
                toast.error(`${err.response.data.error}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
    },


})(Sentencing)

export default Form;