import React, { useContext } from 'react';
import axios from 'axios';

import { Box, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { apiRoute } from '../../../../App';
import { CaseContext } from '../../../../context/CaseContext';
import { formatMoney } from '../../../../Utils';
import UserContext from '../../../../context/UserContext';

const ChargesViewTable = (props) => {
    const { setViolationBalance, setViolationView, setViolation, chargesRows } = props;

    const {
        setViolationId,
        citationView,
    } = useContext(CaseContext);

    const { useFeeSchedule, entityId } = useContext(UserContext);

    const getViolationId = (params) => {
        setViolationId(params.row.id);

        params.row.dueDate = citationView.courtDatetime;
        setViolationView(params.row);

        setViolationBalance(parseFloat(params.row.remainingBalance));

        //* -------------------- VIOLATION GET FOR SENTENCIND INFO (violation) ---------------------
        axios.get(`${apiRoute}/api/ViolationTbls/${params.row.id}`)
            .then(res => {
                let creditPerDay = params.row.remainingBalance / res.data.daysInLieu
                let totalCredit = creditPerDay * res.data.timeServed

                setViolation({ ...res.data, creditApplied: totalCredit });
            })
            .catch(err => {
                console.log("❌ VIOLATION BY VIOLATION ID ERR");
            })
    }

    const columns = [
        {
            field: entityId === '2' ? 'chargeType' : 'currentStatute',
            headerName: entityId === '2' ? 'Charge Type' : 'Statute',
            width: 300,
            flex: 0,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value} sx={{ border: '1px solid white' }} componentsProps={{
                        tooltip: {
                            sx: {
                                border: '1px solid white',
                                borderRadius: '0px',
                                backgroundColor: '#4c4e52',
                                fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : 'inherit'
                            },
                        },
                    }} followCursor arrow>
                        <span style={{ color: params.row.courtAppearRqd === 'Y' ? 'red' : 'inherit', fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : 'inherit' }}>{params.value}</span>
                    </Tooltip>
                )
            }
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 625,
            flex: 0,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value} sx={{ border: '1px solid white' }} componentsProps={{
                        tooltip: {
                            sx: {
                                border: '1px solid white',
                                borderRadius: '0px',
                                backgroundColor: '#4c4e52',
                                fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : 'inherit'
                            },
                        },
                    }} followCursor arrow>
                        <span style={{ color: params.row.courtAppearRqd === 'Y' ? 'red' : 'inherit', fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : 'inherit' }}>{params.value}</span>
                    </Tooltip>
                )
            }
        },
        {
            field: 'remainingBalance',
            headerName: 'Balance',
            width: 300,
            flex: 0,
            align: 'right',
            headerAlign: 'right',
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip
                            title={
                                new Intl.NumberFormat('en-US',
                                    {
                                        style: 'currency',
                                        currency: 'USD',
                                        maximumFractionDigits: 2
                                    })
                                    .format(params.value)}
                            sx={
                                {
                                    border: '1px solid white'
                                }
                            }
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        border: '1px solid white',
                                        borderRadius: '0px',
                                        backgroundColor: '#4c4e52',
                                        fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : 'inherit'
                                    },
                                },
                            }}
                            followCursor
                            arrow
                        >
                            <span
                                style={
                                    {
                                        color: params.row.courtAppearRqd === 'Y' ? 'red' : 'inherit',
                                        fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : 'inherit'
                                    }
                                }
                            >
                                {
                                    new Intl.NumberFormat('en-US',
                                        {
                                            style: 'currency',
                                            currency: 'USD',
                                            maximumFractionDigits: 2
                                        })
                                        .format(params.value)
                                }
                            </span>
                        </Tooltip>
                    </>
                )
            },
        }
    ];

    const minHeight = chargesRows.length < 1 ? '21.5vh' : 'inherit';

    return (
        <Box sx={{ height: minHeight }}>
            <DataGrid
                rows={chargesRows}
                columns={columns.map((column, index) => ({
                    ...column,
                    headerClassName: index === 0 ? 'first-column-header' : '',
                    cellClassName: index === 0 ? 'first-column-cell' : '',
                }))}
                disableMultipleRowSelection
                onRowClick={getViolationId}
                density="compact"
                initialState={{
                    pagination: { paginationModel: { pageSize: 25 } },
                }}
                pageSizeOptions={[25, 50, 100]}
                localeText={{
                    noRowsLabel:
                        'No violations with outstanding balances',
                }}
                sx={{
                    '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                        marginTop: '1em',
                        marginBottom: '1em',
                    },
                    maxHeight: '21.5vh',
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    }, "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                        outline: "none !important",
                    },
                    '.first-column-header': {
                        paddingLeft: '5em', // Adjust the padding value as needed
                    },
                    '.first-column-cell': {
                        paddingLeft: '5em', // Adjust the padding value as needed
                    },
                }}
            />
        </Box>
    );
}

export default ChargesViewTable;