import React, { useState, useContext } from 'react';

import { Box, Button, Paper } from '@mui/material';

const PrintFineScheduleModal = (props) => {
    const { handleGenerateReportClick, handleClose } = props

    return (
        <Paper elevation={10}>
            <Paper sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                <h1>
                    <strong>Print Out Fine Schedule</strong>
                </h1>
            </Paper>
            <Box sx={{ p: '1vh' }}>
                <Box sx={{ textAlign: 'center', mb: '2vh' }}>
                    <h2>This process might take a few moments, would you like to continue?</h2>
                </Box>
                <hr />
                <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'right' }}>
                    <Button
                        onClick={() => { handleGenerateReportClick(); handleClose() }}
                        variant="contained"
                        sx={{ backgroundColor: 'steelblue', color: 'white' }}
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={() => handleClose()}
                        variant="contained"
                        sx={{ backgroundColor: 'steelblue', color: 'white' }}
                    >
                        No
                    </Button>
                </Box>
            </Box>
        </Paper>
    )
};

export default PrintFineScheduleModal