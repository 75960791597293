import React, { useState, useContext } from 'react';
import axios from 'axios';

import { Box, Paper, Modal, Backdrop, CircularProgress } from '@mui/material';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BatchTable from '../components/tables/views/BatchTable';
import BatchSearch from '../components/sections/batches/BatchSearch';
import BatchButtons from '../components/sections/batches/BatchButtons.js';
import BatchModals from '../components/modals/views/BatchModals';

import { BatchContext } from '../context/BatchMgmtContext';
import { ThemeModeContext, apiRoute } from '../App';
import UserContext from '../context/UserContext';

const BatchManagement = () => {
    const {
        setDate,
        setUsers,
        setBatchStatus,
        setBatchNumber,
        getBatches,
        setStatusCheck,
        setStartDate,
        setEndDate,
        onSubmitHandler
    } = useContext(BatchContext);

    const { mode } = useContext(ThemeModeContext);
    const { banks } = useContext(UserContext);

    const [newBatch, setNewBatch] = useState({});
    const [selectedRow, setSelectedRow] = useState({});
    const [excludeDate, setExcludeDate] = useState(false);
    const [rowSelected, setRowSelected] = useState(false);
    const [batchList, setBatchList] = useState([]);
    const [reportLoading, setReportLoading] = useState(false);

    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [modalWidth, setModalWidth] = useState('');
    const [loading, setLoading] = useState(false);
    const [accountingPeriod, setAccountingPeriod] = useState(`${new Date().getMonth() + 1 > 12 ? 1 : new Date().getMonth() + 1}/${new Date().getFullYear()}`);
    const [selectedBank, setSelectedBank] = useState(banks[0]?.pkBankId);

    const handleOpen = async (type, width) => {
        if (type === 'openBatch') {
            axios.get(`${apiRoute}/api/batchtbl/newestbatch`)
                .then((res) => {
                    setNewBatch(res.data);
                })
                .then(() => {
                    setModalType(type);
                    setModalWidth(width);
                    setModal(true);
                })
                .catch(err => console.log(err));
        } else {
            setModalType(type);
            setModalWidth(width);
            setModal(true);
        }
    };

    const handleClose = () => {
        setModalType('');
        setModalWidth('');
        setModal(false);
    };

    const resetSearch = () => {
        setDate('');
        setUsers('allUsers');
        setBatchStatus('open');
        setBatchNumber('');
        setExcludeDate(false);
        setRowSelected(false);
        setStatusCheck('Open');
        getBatches();
        setStartDate('');
        setEndDate('');
    };

    const batchReportSubmitHandler = (calledFor, value) => {
        const apiEndPoint =
            calledFor === 'Drawer Count'
                ? `${apiRoute}/api/BatchCloseoutTbls/DrawerCountReport?BatchId=${value}`// pkBatchId
                : `${apiRoute}/api/BatchCloseoutTbls/batchCloseoutReport?batchNumber=${value}`;// batchNumber
        if (selectedRow?.pkBatchId) {
            setReportLoading(true);
            axios.get(apiEndPoint, { responseType: 'arraybuffer' })
                .then((response) => {
                    const arrayBufferView = new Uint8Array(response.data);
                    const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
                    const dataUrl = URL.createObjectURL(blob);
                    window.open(dataUrl, '_blank');
                })
                .catch((error) => {
                    console.error(`Error fetching ${calledFor} Report`, error);
                })
                .finally(() => setReportLoading(false));
        } else {
            console.log('No batches selected');
        }
    };

    const depositSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        const batchListJson = JSON.stringify(batchList);
        const batchNumList = JSON.parse(batchListJson);
        const pkBatchIds = [];



        // Iterate through the objects in the JSON and extract pkBatchId values
        for (const batch of batchNumList) {
            if (batch.hasOwnProperty("batchNumber")) {
                pkBatchIds.push(batch.pkBatchId.toString());
            }
        }
        // Combine the extracted values into a single string
        const pkBatchIdString = pkBatchIds.join(",");
        try {

            // Make the Axios GET request with the batchListQueryParam as a parameter
            //await axios.get(`${apiRoute}/api/BatchTbl/deposit/${pkBatchIdString}`, {

            const deposit = await createGlDepositRecord(batchList, accountingPeriod);
            await axios.get(`${apiRoute}/api/BatchTbl/deposit/${pkBatchIdString}/${deposit[0].referenceNo}`, {
                responseType: 'arraybuffer',
            })
                .then((response) => {
                    const arrayBufferView = new Uint8Array(response.data);
                    const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    return url;
                })
                .then((url) => {
                    window.open(url);
                    handleClose();
                })
                .catch((error) => {
                    toast.error(`Error occurred generating deposit report: ${error?.response?.data}`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                    console.error('API Error:', error);
                    console.log('HTTP Status:', error.response.status);
                    console.log('Response Data:', error.response.data);
                })
                .finally(() => { setLoading(false); setBatchList([]);  onSubmitHandler(e); });
        }
        catch (err) {
            toast.error(`Error occurred while creating GL Deposit Record: ${err?.response?.data}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            console.error('Error occurred depositSubmitHandler', err);
        }
    };

    const createGlDepositRecord = async (batchList, accountingPeriod) => {
        const period = accountingPeriod?.split('/');
        const month = period[0];
        const year = period[1];
        const batchNums = [];

        batchList.forEach((b) => {
            batchNums.push(parseInt(b.batchNumber));
        });

        return await axios.post(`${apiRoute}/api/GlTransactionTbls/deposit/${selectedBank}/${month}/${year}`, batchNums)
            .then(({ data }) => data)
            .catch(err => {
                toast.error(`Error occurred generating deposit record: ${err?.response?.data}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
                console.warn(err);
                return err
            });
    };

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === "dark" ? "#4C4E52" : "white",
            color: mode === "dark" ? "white" : "black"
        },
        '& .MuiInputLabel-root': {
            color: mode === "dark" ? "white" : "black",
            textShadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    };

    return (
        <>
            <Paper sx={{ height: '100%', pb: '1vh' }} elevation={10}>
                <Paper sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '3vh', textAlign: 'center' }} elevation={10}>
                    <h1 style={{ fontWeight: 'bold' }}>Batch Management</h1>
                </Paper>
                <Box sx={{ width: '98%', m: '0 auto' }}>
                    <>
                        <BatchSearch
                            excludeDate={excludeDate}
                            resetSearch={resetSearch}
                            ts={textFieldStyle}
                            setRowSelected={setRowSelected}
                        />
                    </>
                    <>
                        <BatchButtons
                            rowSelected={rowSelected}
                            batchList={batchList}
                            selectedRow={selectedRow}
                            handleOpen={handleOpen}
                            setReportLoading={setReportLoading}
                        />
                    </>
                    <Paper elevation={10} sx={{ mb: '1vh', maxHeight: '55vh' }}>
                        <BatchTable
                            setSelectedRow={setSelectedRow}
                            setRowSelected={setRowSelected}
                            setBatchList={setBatchList}
                            handleOpen={handleOpen}
                            setReportLoading={setReportLoading}
                            batchReportSubmitHandler={batchReportSubmitHandler}
                        />
                    </Paper>
                </Box>
            </Paper>
            <Modal open={modal} sx={{ width: modalWidth, margin: "5vh auto" }}>
                <>
                    <BatchModals
                        ts={textFieldStyle}
                        modalType={modalType}
                        handleClose={handleClose}
                        newBatch={newBatch}
                        handleOpen={handleOpen}
                        selectedRow={selectedRow}
                        resetSearch={resetSearch}
                        depositSubmitHandler={depositSubmitHandler}
                        depositLoad={loading}
                        accountingPeriod={accountingPeriod}
                        setAccountingPeriod={setAccountingPeriod}
                        banks={banks}
                        selectedBank={selectedBank}
                        setSelectedBank={setSelectedBank}
                    />
                </>
            </Modal>
            {reportLoading && (
                <Backdrop open sx={{ zIndex: 1 }}>
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <CircularProgress />
                    </div>
                </Backdrop>
            )}
            
        </>
    )
}

export default BatchManagement;