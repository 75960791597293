import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, ButtonGroup, Grid, LinearProgress, MenuItem, Paper, TextField } from '@mui/material';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { apiRoute, ThemeModeContext } from '../../../App';
import { CaseContext } from '../../../context/CaseContext';

const CreateCaseForm = (props) => {
    const { navigate } = props;

    const { mode } = useContext(ThemeModeContext);

    const {
        agencyCode,
        courtNum,
        divisionCode,
        caseTypes,
        statusCodes,
        suffixCodes,
        caseYear,
    } = useContext(CaseContext);

    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const today = new Date();

    const initialBondState = {
        caseTypes: '',
        clerkCaseNum: '',
        citNumber: '',
        violationDate: '',
        courtDatetime: '',
        lastName: '',
        firstName: '',
        midInit: '',
        suffix: '',
        agency: '',
        court: '',
        division: '',
        caseStatus: '',
        caseYear: '',
        errors: {}
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                const updatedErrors = { ...state.errors };
                delete updatedErrors[action.field];
                return {
                    ...state,
                    errors: updatedErrors,
                };
            default:
                return state;
        }
    };


    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const handleBlur = (field, value) => {
        let error = null;
        if (field === 'citNumber' || field === 'clerkCaseNum') {
            if (!value.trim()) {
                error = 'Citation or Case Number is required';
            } else {
                // Clear the error for the opposite field when one of them has a value
                const oppositeField = field === 'citNumber' ? 'clerkCaseNum' : 'citNumber';
                dispatch({ type: 'CLEAR_FIELD_ERROR', field: oppositeField });
            }
        } else {
            switch (field) {
                case 'caseTypes':
                    if (!value) error = 'Case Type is required';
                    dispatch({ type: 'CLEAR_FIELD_ERROR', field: 'caseTypes' })
                    break;
                case 'caseYear':
                    if (!value || value.length < 4 || value.length > 4) {
                        error = 'Case Year is required';
                    }
                    dispatch({ type: 'CLEAR_FIELD_ERROR', field: 'caseYear' })
                    break;
                case 'violationDate':
                    if (!value) {
                        error = 'Violation Date is required';
                    } else if (new Date(value) > today) {
                        error = 'Cannot be a future date.';
                    }
                    dispatch({ type: 'CLEAR_FIELD_ERROR', field: 'violationDate' })
                    break;
                case 'courtDatetime':
                    if (!value) {
                        error = "Pay By Date is required"
                    }
                    if (new Date(value) < new Date(state.violationDate)) {
                        error = 'Cannot be before violation date.';
                    }
                    dispatch({ type: 'CLEAR_FIELD_ERROR', field: 'courtDatetime' })
                    break;
                case 'firstName':
                    if (!value) {
                        error = 'First Name is required';
                    }
                    dispatch({ type: 'CLEAR_FIELD_ERROR', field: 'firstName' })
                    break;
                case 'lastName':
                    if (!value) {
                        error = 'Last Name is required';
                    }
                    dispatch({ type: 'CLEAR_FIELD_ERROR', field: 'lastName' })
                    break;
                default:
                    break;
            }
        }

        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const [state, dispatch] = useReducer(reducer, initialBondState);

    const createCitation = (e) => {
        e.preventDefault();

        handleBlur('violationDate', state.violationDate);
        handleBlur('courtDatetime', state.courtDatetime);
        handleBlur('firstName', state.firstName);
        handleBlur('lastName', state.lastName);
        handleBlur('caseYear', state.caseYear);
        handleBlur('caseTypes', state.caseTypes);
        state.clerkCaseNum === '' && state.citNumber === '' && handleBlur('citNumber', state.citNumber);
        state.clerkCaseNum === '' && state.citNumber === '' && handleBlur('clerkCaseNum', state.clerkCaseNum);
        setIsSubmitting(true);
    }

    useEffect(() => {
        if (isSubmitting) {
            if (Object.keys(state.errors).length === 0 || Object.values(state.errors).every(v => v === null || v === undefined)) {
                const citationData = {
                    citNumber: state.citNumber || null,
                    courtDatetime: state.courtDatetime || '9999-12-31',
                    lastName: state.lastName,
                    firstName: state.firstName,
                    midInit: state.midInit,
                    suffix: state.suffix,
                    IssueAgency: state.agency,
                    court: state.court,
                    DivisionCode: state.division,
                    caseStatus: state.caseStatus,
                };
                const caseYear = !state?.caseYear ? '' : state?.caseYear;
                const caseNum = !state?.clerkCaseNum ? 'noCase' : state?.clerkCaseNum;
                const caseType = !state?.caseTypes ? 'noCaseType' : state?.caseTypes;
                const vioDate = !state?.violationDate ? 'noDate' : state?.violationDate;

                setLoading(true);
                axios.post(`${apiRoute}/api/citationtbls/${vioDate}/${caseNum}/${caseType}/${caseYear}`, citationData)
                    .then((res) => {
                        toast.success('Case Created!', {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        return res.data;
                    }).then((data) => {
                        const masterTransactionData = {
                            FkCaseId: data.caseTbl.pkCaseId,
                            DefendantName: `${data.citationTbl.firstName} ${data.citationTbl.lastName}`,
                            DefendantAddress1: data.citationTbl.address1 || "",
                            DefendantAddress2: data.citationTbl.address2 || "",
                            DefendantCity: data.citationTbl.city || "",
                            DefendantState: data.citationTbl.state || "",
                            DefendantZip: data.citationTbl.zipCode || "",
                        };
                        axios.post(`${apiRoute}/api/masterTransactionTbls`, masterTransactionData);
                        navigate(`/view/${data.caseTbl.pkCaseId}`);
                    })
                    .catch((err) => {
                        if (err?.response?.data) {
                            let errorMessage = err?.response?.data;
                            return toast.error(errorMessage, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                            });
                        } else {
                            return toast.error('Unknown Error Occurred, please reach out to support', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                            });
                        }
                    })
                    .finally(() => setLoading(false))
            } else {
                setIsSubmitting(false);
            }
        }
    }, [state.errors, isSubmitting]);

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === "dark" ? "#4C4E52" : "white",
            color: mode === "dark" ? "white" : "black"
        },
        '& .MuiInputLabel-root': {
            color: mode === "dark" ? "white" : "black",
            textShadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    };

    return (
        <>
            <Paper elevation={10}>
                <Paper sx={{ backgroundColor: 'steelblue', color: 'white', height: '3vh', m: 'auto', p: '.5vh' }}>
                    <h5 style={{ fontWeight: 'bold', fontSize: 'inherit' }}>Create New Case/Citation</h5>
                </Paper>
                <form>
                    <Box sx={{ display: "flex", gap: '1rem', mt: '1vh', pl: '.5rem' }}>
                        <Box sx={{ width: '74.99%' }}>
                            <Grid container>
                                <Grid item sm={1.85}>
                                    <Box m={.5}>
                                        <TextField
                                            sx={textFieldStyle}
                                            id="caseTypes"
                                            label="Case Type"
                                            name="caseTypes"
                                            select
                                            fullWidth
                                            value={state?.caseTypes}
                                            onChange={(e) => handleFieldChange('caseTypes', e.target.value)}
                                            onBlur={(e) => handleBlur('caseTypes', e.target.value)}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            required
                                            error={!!state?.errors?.caseTypes}
                                            helperText={state?.errors?.caseTypes}
                                            color={!!state?.errors?.caseTypes && 'warning'}
                                        >
                                            <MenuItem value={''}>N/A</MenuItem>
                                            {
                                                caseTypes?.map(({ code, description }) => {
                                                    return <MenuItem key={code} value={code}>{description}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Box>
                                </Grid>

                                <Grid item sm={.98}>
                                    <Box m={.5}>
                                        <TextField
                                            sx={textFieldStyle}
                                            id="caseYear"
                                            label="Case Year"
                                            fullWidth
                                            select
                                            value={state?.caseYear}
                                            onChange={(e) => handleFieldChange('caseYear', e.target.value)}
                                            onBlur={(e) => handleBlur('caseYear', e.target.value)}
                                            variant="outlined"
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: '20em',
                                                        },
                                                    },
                                                },
                                            }}
                                            InputLabelProps={
                                                {
                                                    shrink: true
                                                }
                                            }
                                            required
                                            inputProps={
                                                {
                                                    maxLength: 4,
                                                    minLength: 4
                                                }
                                            }
                                            error={!!state?.errors?.caseYear}
                                            helperText={state?.errors?.caseYear}
                                            color={!!state?.errors?.caseYear && 'warning'}
                                        >
                                            {
                                                caseYear?.map((year) => {
                                                    return <MenuItem key={year} value={year}>{year}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Box>
                                </Grid>
                                <Box m={.5}>
                                    <TextField
                                        sx={textFieldStyle}
                                        id="clerkCaseNum"
                                        label="Clerk Case # *"
                                        value={state?.clerkCaseNum}
                                        onChange={(e) => handleFieldChange('clerkCaseNum', e.target.value)}
                                        onBlur={(e) => {
                                            if (state.citNumber.length < 1) {
                                                handleBlur('clerkCaseNum', e.target.value)
                                            }
                                        }}
                                        helperText={state?.errors?.clerkCaseNum}
                                        error={!!state?.errors?.clerkCaseNum}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        color={!!state?.errors?.clerkCaseNum && 'warning'}
                                    />
                                </Box>
                                <Box m={.5}>
                                    <TextField
                                        sx={textFieldStyle}
                                        id="citNumber"
                                        label="Citation # *"
                                        value={state?.citNumber}
                                        onChange={(e) => handleFieldChange('citNumber', e.target.value)}
                                        onBlur={(e) => {
                                            if (state.clerkCaseNum.length < 1) {
                                                handleBlur('citNumber', e.target.value)
                                            }
                                        }} helperText={state?.errors?.citNumber}
                                        error={!!state?.errors?.citNumber}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        color={!!state?.errors?.citNumber && 'warning'}
                                    />
                                </Box>
                                <Box m={.5}>
                                    <TextField
                                        sx={textFieldStyle}
                                        id="violationDate"
                                        label="Violation / Court Date"
                                        type="date"
                                        value={state?.violationDate}
                                        onChange={(e) => handleFieldChange('violationDate', e.target.value)}
                                        onBlur={(e) => handleBlur('violationDate', e.target.value)}
                                        helperText={state?.errors?.violationDate}
                                        error={!!state?.errors?.violationDate}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        required
                                        color={!!state?.errors?.violationDate && 'warning'}
                                    />
                                </Box>
                                <Box m={.5}>
                                    <TextField
                                        sx={textFieldStyle}
                                        id="courtDatetime"
                                        label="Pay By Date"
                                        type="date"
                                        value={state?.courtDatetime}
                                        onChange={(e) => handleFieldChange('courtDatetime', e.target.value)}
                                        onBlur={(e) => handleBlur('courtDatetime', e.target.value)}
                                        helperText={state?.errors?.courtDatetime}
                                        error={!!state?.errors?.courtDatetime}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        required
                                        color={!!state?.errors?.courtDatetime && 'warning'}
                                    />
                                </Box>
                                <Grid item sm={2.50}>
                                    <Box m={.5}>
                                        <TextField
                                            sx={{ ...textFieldStyle, minWidth: '10rem' }}
                                            id="caseStatus"
                                            label="Case Status"
                                            name="caseStatus"
                                            select
                                            width="auto"
                                            value={state?.caseStatus}
                                            onChange={(e) => handleFieldChange('caseStatus', e.target.value)}
                                            onBlur={(e) => handleBlur('caseStatus', e.target.value)}
                                            helperText={state?.errors?.caseStatus}
                                            error={!!state?.errors?.caseStatus}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            color={!!state?.errors?.caseStatus && 'warning'}
                                        >
                                            {statusCodes?.map(({ defaultValue }) => (
                                                <MenuItem key={defaultValue} value={defaultValue}>
                                                    {defaultValue}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Box m={.5}>
                                    <TextField
                                        sx={textFieldStyle}
                                        id="lastName"
                                        label="Last"
                                        value={state?.lastName}
                                        onChange={(e) => handleFieldChange('lastName', e.target.value)}
                                        onBlur={(e) => handleBlur('lastName', e.target.value)}
                                        helperText={state?.errors?.lastName}
                                        error={!!state?.errors?.lastName}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        required
                                        color={!!state?.errors?.lastName && 'warning'}
                                    />
                                </Box>
                                <Box m={.5}>
                                    <TextField
                                        sx={textFieldStyle}
                                        id="firstName"
                                        label="First"
                                        value={state?.firstName}
                                        onChange={(e) => handleFieldChange('firstName', e.target.value)}
                                        onBlur={(e) => handleBlur('firstName', e.target.value)}
                                        helperText={state?.errors?.firstName}
                                        error={!!state?.errors?.firstName}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        required
                                        color={!!state?.errors?.firstName && 'warning'}
                                    />
                                </Box>
                                <Grid item sm={.75}>
                                    <Box m={.5}>
                                        <TextField
                                            sx={textFieldStyle}
                                            id="midInit"
                                            label="M.I."
                                            value={state?.midInit}
                                            onChange={(e) => handleFieldChange('midInit', e.target.value.replace(/[^a-zA-Z]/g, ''))}
                                            onBlur={(e) => handleBlur('midInit', e.target.value)}
                                            helperText={state?.errors?.midInit}
                                            error={!!state?.errors?.midInit}
                                            inputProps={{ maxLength: 1 }}
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            color={!!state?.errors?.midInit && 'warning'}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item sm={.75}>
                                    <Box m={.5}>
                                        <TextField
                                            sx={textFieldStyle}
                                            id="suffix"
                                            label="Suffix"
                                            name="suffix"
                                            value={state?.suffix}
                                            onChange={(e) => handleFieldChange('suffix', e.target.value)}
                                            onBlur={(e) => handleBlur('suffix', e.target.value)}
                                            helperText={state?.errors?.suffix}
                                            error={!!state?.errors?.suffix}
                                            select
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            color={!!state?.errors?.suffix && 'warning'}
                                        >
                                            {suffixCodes?.map(({ defaultValue }) => (
                                                <MenuItem key={defaultValue} value={defaultValue}>
                                                    {defaultValue === '""' ? "N/A" : defaultValue}
                                                </MenuItem>
                                            )
                                            )}
                                        </TextField>
                                    </Box>
                                </Grid>
                                <Grid item sm={2.5}>
                                    <Box m={.5}>
                                        <TextField
                                            sx={textFieldStyle}
                                            id="agency"
                                            label="Agency"
                                            name="agency"
                                            value={state?.agency}
                                            onChange={(e) => handleFieldChange('agency', e.target.value)}
                                            onBlur={(e) => handleBlur('agency', e.target.value)}
                                            helperText={state?.errors?.agency}
                                            error={!!state?.errors?.agency}
                                            select
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            color={!!state?.errors?.agency && 'warning'}
                                        >
                                            <MenuItem value={''}>N/A</MenuItem>
                                            {
                                                agencyCode?.map(({ code, description }) => {
                                                    return <MenuItem key={code} value={code}>{description}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Box>
                                </Grid>
                                <Grid item sm={2}>
                                    <Box m={.5}>
                                        <TextField
                                            sx={textFieldStyle}
                                            id="court"
                                            label="Court"
                                            name="court"
                                            value={state?.court}
                                            onChange={(e) => handleFieldChange('court', e.target.value)}
                                            onBlur={(e) => handleBlur('court', e.target.value)}
                                            helperText={state?.errors?.court}
                                            error={!!state?.errors?.court}
                                            select
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            color={!!state?.errors?.court && 'warning'}
                                        >
                                            <MenuItem value={''}>N/A</MenuItem>
                                            {
                                                courtNum?.map(({ courtNum, displayCourtName }) => {
                                                    return <MenuItem key={courtNum} value={courtNum}>{displayCourtName}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Box>
                                </Grid>
                                <Grid item sm={1.25}>
                                    <Box m={.5}>
                                        <TextField
                                            sx={textFieldStyle}
                                            id="division"
                                            label="Division"
                                            name="division"
                                            value={state?.division}
                                            onChange={(e) => handleFieldChange('division', e.target.value)}
                                            onBlur={(e) => handleBlur('division', e.target.value)}
                                            helperText={state?.errors?.division}
                                            error={!!state?.errors?.division}
                                            select
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            color={!!state?.errors?.division && 'warning'}
                                        >
                                            <MenuItem value={''}>N/A</MenuItem>
                                            {
                                                divisionCode?.map(({ divisionCode, name }) => {
                                                    return <MenuItem key={divisionCode} value={divisionCode}>{name}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Paper sx={{ textAlign: 'center', width: "20%", margin: "0 2rem 1vh auto", height: '14vh' }} elevation={10}>
                            <Paper sx={{ backgroundColor: 'steelblue', color: 'white', height: '3vh', m: 'auto', p: '.5vh' }}>
                                <h4 style={{ fontWeight: 'bold', fontSize: 'inherit' }}>Case Options</h4>
                            </Paper>
                            <ButtonGroup orientation="vertical" fullWidth sx={{ paddingRight: ".5rem", paddingLeft: ".5rem", mt: '1vh' }}>
                                <Box mb={1}>
                                    <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white", height: '4vh' }} onClick={createCitation}>Save</Button>
                                </Box>
                                <Box mb={1}>
                                    <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                        <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white", height: '4vh' }}>Cancel</Button>
                                    </Link>
                                </Box>
                            </ButtonGroup>
                        </Paper>
                    </Box>
                </form>
            </Paper >

            {
                loading && (
                    <>
                        <LinearProgress />
                    </>
                )
            }
        </>
    )
}

export default CreateCaseForm;