import React, { useState, useContext } from 'react'
import axios from 'axios';

import { Box, Button, TextField, LinearProgress, Paper, MenuItem } from '@mui/material';
import { green, grey } from '@mui/material/colors';

import { ThemeModeContext, apiRoute } from '../../../App';
import { Filters } from '../../../Utils';

const BatchCollectionFilterModal = ({ handleClose, batchNum }) => {
    const { mode } = useContext(ThemeModeContext);

    const [loadingReport, setLoadingReport] = useState(false);
    const [success, setSuccess] = useState(false);
    const [selectedCaseType, setSelectedCaseType] = useState("NONE");

    const buttonSx = {
        ...(success ? {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        } :
            {
                bgcolor: "steelblue",
                color: "white",
            }),
        '&.Mui-disabled': {
            bgcolor: grey[700],
            color: 'rgba(255, 255, 255, 0.5)'
        }
    };

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === "dark" ? "#4C4E52" : "white",
            color: mode === "dark" ? "white" : "black"
        },
        '& .MuiInputLabel-root': {
            color: mode === "dark" ? "white" : "black",
            textshadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    };

    const handleGenerateReportClick = () => {
        setLoadingReport(true);
        axios.get(`${apiRoute}/api/CollectionReportViews/BatchCollection?batchNumber=${batchNum}&filterOption=${selectedCaseType}`, { responseType: 'arraybuffer' })
            .then((response) => {
                handleClose();
                const arrayBufferView = new Uint8Array(response.data);
                const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
                const dataUrl = URL.createObjectURL(blob);
                window.open(dataUrl, '_blank');
            })
            .catch(error => {
                handleClose();
                console.error('Error fetching report from front end:', error);
            })
            .finally(() => setLoadingReport(false));
    };

    return (
        <Paper elevation={10}>
            <Paper sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                <h1 style={{ fontWeight: 'bold' }}>Batch Collection Filter</h1>
            </Paper>
            <Box p={1} sx={{ textAlign: 'center' }}>
                <h5 style={{ marginY: '1vh' }}>
                    Select a case type to filter by.
                </h5>
                <hr />
                <TextField
                    sx={{ ...textFieldStyle, textAlign: 'center', width: '75%' }}
                    
                    id="filterBy"
                    label="Filter By"
                    select
                    value={selectedCaseType}
                    onChange={e => { setSelectedCaseType(e.target.value); }}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    disabled={loadingReport}
                >
                    {Filters?.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                </TextField>
                <hr />
                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: '1rem', mt: '3vh', mb: '1vh' }}>
                    <Button variant="contained" sx={buttonSx} disabled={loadingReport} onClick={handleGenerateReportClick}>Generate Report</Button>
                    <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={handleClose}>Cancel</Button>
                </Box>
            </Box>
            {loadingReport && (
                <LinearProgress sx={{ width: '99.5%', m: '0 auto' }} />
            )}
        </Paper>
    )
}

export default BatchCollectionFilterModal;