/* eslint-disable eqeqeq */
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Button, Checkbox, FormControlLabel, Modal, Paper, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';

import PaymentModals from '../../modals/views/PaymentModals';
import ChargesViewTable from '../../tables/views/paymentsTab/ChargesTable';

import { apiRoute } from '../../../App';
import { BatchContext } from '../../../context/BatchMgmtContext';
import { CaseContext } from '../../../context/CaseContext';

const ChargesBox = (props) => {
    const {
        modal,
        handleClose,
        handleOpen,
        violationBalance,
        setViolationBalance,
        openBatches,
        updateCaseBalance,
        violation,
        setViolation,
        modalType,
        modalWidth,
        ts,
        loading,
        setLoading,
        selectedPayment,
        newBatch,
        receiptNumber,
        setReceiptNumber,
        state,
        retrieveReceiptPayment,
        setRetrieveReceiptPayment
    } = props;

    const { caseView, setCaseView, partialPaymentChecks, citationView, chargesRows, violationId, balance } = useContext(CaseContext);
    const { setPaymentBatchNumber } = useContext(BatchContext);

    const [violationView, setViolationView] = useState({});
    const currentDate = new Date();
    const courtDate = new Date(citationView.courtDatetime);
    const { caseNumber } = useParams();


    // Check if Sys Codes have ALLOW_PARTIAL entry and its defaultValue is true
    const isPartialPaymentAllowedInSysCodes = partialPaymentChecks.some((item) => {
        return item.description === 'ALLOW_PARTIAL' && item.defaultValue === 'Y';
    });

    const overridePartialPaymentHandler = (e) => {
        const payload = {
            ...caseView,
            overridedPartialPayment: e.target.checked ? 'Y' : 'N',
        };
        axios.put(`${apiRoute}/api/CaseTbls/${caseNumber}`, payload)
            .then(() => {
                setCaseView(payload);
            })
            .catch((err) => {
                toast.error(`Error ${err.response.status} while disabling partial payments on this case.`, {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            });
    };
    console.log("chargesRows: ", chargesRows)

    const rows = chargesRows.filter(r => r.amendedDate === "" || r.amendedDate == null)

    return (
        <Paper sx={{ m: '1vh 0' }} elevation={10}>
            <Paper sx={{ backgroundColor: 'steelblue', color: 'white', height: '3vh', m: 'auto', p: '.5vh' }}>
                <h6 style={{ fontWeight: 'bold', fontSize: 'inherit' }}>Violations With Outstanding Balances</h6>
            </Paper>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0.5rem', width: '99%', m: '0 auto' }}>
                <Paper sx={{ width: '84.5%', height: '21.5vh' }} elevation={10}>
                    <ChargesViewTable
                        setViolationBalance={setViolationBalance}
                        setViolationView={setViolationView}
                        setViolation={setViolation}
                        chargesRows={rows}
                    />
                </Paper>

                <Paper sx={{ width: '15%', textAlign: 'center', p: '.5rem' }} elevation={10}>

                    <Box>
                        <strong>Payment Options</strong>
                    </Box>
                    <hr />
                    <Box sx={{ width: '99%', m: '0 auto' }}>
                        <Tooltip
                            title={
                                chargesRows.length < 1 && balance === 0.00
                                    ? 'No outstanding balances to make a payment on'
                                    : ''
                            }
                            placement="left"
                        >
                            <span>
                                <Button
                                    disabled={chargesRows.length < 1 || caseView.isCasePaymentLocked == true}
                                    onClick={() => {
                                        if (!violationId) {
                                            toast.error(`Select an unpaid violation to accept a payment`, {
                                                position: 'top-right',
                                                autoClose: 2000,
                                                hideProgressBar: true,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: 'colored',
                                            });
                                        } else {
                                            setReceiptNumber('');
                                            handleOpen('newPayment', '75%');
                                        }
                                    }}
                                    variant="contained"
                                    sx={{
                                        backgroundColor: 'steelblue',
                                        color: 'white',
                                        width: '100%',
                                        mb: '1vh',
                                    }}
                                >
                                    Time Served
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip
                            title={
                                chargesRows.length < 1 && balance === 0.00
                                    ? 'No outstanding balances to make a payment on'
                                    : ''
                            }
                            placement="left"
                        >
                            <span>
                                <Button
                                    disabled={chargesRows.length < 1 || caseView.isCasePaymentLocked == true}
                                    onClick={() => {
                                        handleOpen('quickPayment', '75%');
                                        setReceiptNumber('');
                                    }}
                                    variant="contained"
                                    sx={{ backgroundColor: 'steelblue', color: 'white', width: '100%', mb: '1vh' }}
                                >
                                    Cash & Checks
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip
                            title={
                                chargesRows.length < 1 && balance === 0.00
                                    ? 'No outstanding balances to make a payment on'
                                    : ''
                            }
                            placement="left"
                        >
                            <span>
                                <Button
                                    disabled={chargesRows.length < 1 || caseView.isCasePaymentLocked == true}
                                    onClick={() => {
                                        setViolation('none');
                                        setPaymentBatchNumber('');
                                        setReceiptNumber('');
                                        handleOpen('creditCard', '60%')
                                    }}
                                    variant="contained"
                                    sx={{ backgroundColor: 'steelblue', color: 'white', width: '100%' }}
                                >
                                    Credit Card
                                </Button>
                            </span>
                        </Tooltip>
                        <Modal
                            open={modal}
                            sx={
                                {
                                    width: modalWidth,
                                    margin: '2vh auto'
                                }
                            }
                        >
                            <PaymentModals
                                handleClose={handleClose}
                                violationBalance={violationBalance}
                                openBatches={openBatches}
                                violationView={violationView}
                                updateCaseBalance={updateCaseBalance}
                                violation={violation}
                                setViolation={setViolation}
                                modalType={modalType}
                                ts={ts}
                                loading={loading}
                                setLoading={setLoading}
                                selectedPayment={selectedPayment}
                                newBatch={newBatch}
                                handleOpen={handleOpen}
                                receiptNumber={receiptNumber}
                                setReceiptNumber={setReceiptNumber}
                                citationState={state}
                                setRetrieveReceiptPayment={setRetrieveReceiptPayment}
                                retrieveReceiptPayment={retrieveReceiptPayment}
                                overridedPartialPayment={caseView?.overridedPartialPayment}
                            />
                        </Modal>
                    </Box>
                </Paper>

            </Box>
            <Box ml={3}>
                {isPartialPaymentAllowedInSysCodes && (
                    <FormControlLabel
                        label="Disallow Partial Payments"
                        control={
                            <Checkbox
                                checked={caseView?.overridedPartialPayment && caseView?.overridedPartialPayment === 'Y'}
                                onChange={overridePartialPaymentHandler}
                            />
                        }
                    />
                )}
            </Box>
        </Paper>
    );
}

export default ChargesBox;