import React, { useState, useContext } from 'react'
import axios from 'axios';

import { Box, Button, TextField, LinearProgress, Paper } from '@mui/material';
import { green, grey } from '@mui/material/colors';

import { ThemeModeContext, apiRoute } from '../../../App';

const CollectionReportsModal = ({ handleClose, reportPath }) => {
    const { mode } = useContext(ThemeModeContext);

    const [calendarOpen, setCalendarOpen] = useState(false);
    const currentDate = new Date();
    const formattedDefaultDate = currentDate.toISOString().split('T')[0];
    const [startDate, setStartDate] = useState(formattedDefaultDate);
    const [endDate, setEndDate] = useState(formattedDefaultDate);
    const [loadingReport, setLoadingReport] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState({ startDate: '', endDate: '' });

    const buttonSx = {
        ...(success ? {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        } :
            {
                bgcolor: "steelblue",
                color: "white",
            }),
        '&.Mui-disabled': {
            bgcolor: grey[700],
            color: 'rgba(255, 255, 255, 0.5)'
        }
    };

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === "dark" ? "#4C4E52" : "white",
            color: mode === "dark" ? "white" : "black"
        },
        '& .MuiInputLabel-root': {
            color: mode === "dark" ? "white" : "black",
            textshadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    };

    const handleDateIconClick = () => {
        setCalendarOpen(!calendarOpen);
    };

    const handleDateInputClick = () => {
        setCalendarOpen(!calendarOpen);
    };

    const handleGenerateReportClick = () => {
        if (startDate > endDate) {
            setErrors({
                startDate: "Starting date cannot come after ending date",
                endDate: "Ending date cannot come before starting date"
            });
            return;
        }

        setErrors({ startDate: null, endDate: null });
        setLoadingReport(true);
        setSuccess(false);
        axios.get(`${apiRoute}/${reportPath}?startDateRange=${startDate}&endDateRange=${endDate}`, { responseType: 'arraybuffer' })
            .then((response) => {
                const arrayBufferView = new Uint8Array(response.data);
                const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
                const dataUrl = URL.createObjectURL(blob);
                setSuccess(true);
                handleClose();
                window.open(dataUrl, '_blank');
            })
            .catch(error => {
                console.error('Error fetching report from front end:', error);
            })
            .finally(() => setLoadingReport(false));
    };

    const handleBlur = (input) => {
        if (startDate > endDate) {
            setErrors(prevErrors => ({ ...prevErrors, [input]: `${input === 'startDate' ? 'Start' : 'End'} date cannot come ${input === 'startDate' ? 'after ending date' : 'before starting date'}` }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, startDate: null, endDate: null }));
        }
    };

    return (
        <Paper elevation={10}>
            <Paper sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                <h1 style={{ fontWeight: 'bold' }}>Report Dates</h1>
            </Paper>
            <Box p={1} sx={{ textAlign: 'center' }}>
                <h5 style={{ marginY: '1vh' }}>
                    Select a date range.
                </h5>
                <hr />
                <TextField
                    sx={{ ...textFieldStyle, textAlign: 'center', width: '75%', mb: "3vh" }}
                    type="date"
                    id="startDateRange"
                    label="Start Date"
                    value={startDate}
                    onChange={e => { setStartDate(e.target.value); handleDateInputClick(); handleDateIconClick(); }}
                    onClick={e => { handleDateInputClick(); }}
                    onBlur={() => handleBlur('startDate')}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    disabled={loadingReport}
                    helperText={errors?.startDate}
                    error={!!errors.startDate}
                />
                <TextField
                    sx={{ ...textFieldStyle, textAlign: 'center', width: '75%' }}
                    type="date"
                    id="endDateRange"
                    label="End Date"
                    value={endDate}
                    onChange={e => { setEndDate(e.target.value); handleDateInputClick(); handleDateIconClick(); }}
                    onClick={e => { handleDateInputClick(); }}
                    onBlur={() => handleBlur('endDate')}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    disabled={loadingReport}
                    helperText={errors?.endDate}
                    error={!!errors.endDate}
                />
                <hr />
                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: '1rem', mt: '3vh', mb: '1vh' }}>
                    <Button variant="contained" sx={buttonSx} disabled={loadingReport} onClick={handleGenerateReportClick}>Generate Report</Button>
                    <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={handleClose}>Cancel</Button>
                </Box>
            </Box>
            {loadingReport && (
                <LinearProgress sx={{ width: '99.5%', m: '0 auto' }} />
            )}
        </Paper>
    )
}

export default CollectionReportsModal;