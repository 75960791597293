import React, { useState, useEffect } from 'react'
import axios from 'axios';

import { Box, Button, Typography, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { apiRoute } from '../../../App';
import { formatDateCST, formatDateTimeCST, formattedDate, formatTime, formatTimeCST } from '../../../Utils';

const ReportsModal = ({ handleClose, reportPath }) => {
    const [disburseDates, setDisburseDates] = useState([]);
    const [loadingDates, setLoadingDates] = useState(true);
    const [selectedDate, setSelectedDate] = useState(false);
    const [previousDate, setPreviousDate] = useState('');

    const columns = [
        {
            field: "Date",
            headerName: "Disburse Date",
            width: 175
        },
        {
            field: "Time",
            headerName: "Disburse Time",
            width: 175
        },
        {
            field: "EnteredBy",
            headerName: "Disbursed By",
            width: 250
        }
    ];
    
    const getDisburseReconDates = () => {
        setLoadingDates(true);
        axios.get(`${apiRoute}/api/DisburseReportViews`)
            .then((res) => {
                const dateRows = res.data.map((row, i) => {
                    return {
                        id: `${row.disburseDate}-${i}`,
                        Date: formatDateCST(row.disburseDate),
                        Time: formatTimeCST(row.disburseDate),
                        EnteredBy: row.enteredBy,
                        Value: row.disburseDate
                    }
                })
                setDisburseDates(dateRows);
            })
            .catch((err) => {
                console.error("error getting disburse dates", err);
            })
            .finally(() => setLoadingDates(false))
    };

    const handleGenerateReportClick = () => {
        if (selectedDate) {
            setLoadingDates(true);

            const getReportRoute = reportPath.includes("Recon") ? `${apiRoute}/${reportPath}?disburseDate=${selectedDate.row.Value}&previousDate=${previousDate}` :
                `${apiRoute}/${reportPath}?disburseDate=${selectedDate.row.Value}`;

            axios.get(getReportRoute, { responseType: 'arraybuffer' })
                .then((response) => {
                    const arrayBufferView = new Uint8Array(response.data);
                    const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
                    const dataUrl = URL.createObjectURL(blob);
                    handleClose();
                    window.open(dataUrl, '_blank');
                })
                .catch(error => {
                    toast.error(`Error ${error.response.status} fetching report`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                    console.error('Error fetching report from front end:', error);
                })
                .finally(() => setLoadingDates(false))
        } else {
            toast.info('Please select a date', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };

    useEffect(() => {
        getDisburseReconDates();
    }, []);

    return (
        <Paper elevation={10}>
            <Paper sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                <h1 style={{ fontWeight: 'bold' }}>Report Dates</h1>
            </Paper>
            <Box p={1}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Select a date from the list below.
                </Typography>
                <hr />
                <Box sx={{ height: "45vh" }}>
                    <DataGrid
                        columns={columns}
                        rows={disburseDates}
                        loading={loadingDates}
                        onCellDoubleClick={handleGenerateReportClick}
                        autoHeight={false}
                        onRowClick={(currentRow) => {
                            setSelectedDate(currentRow);
                            const currentIndex = disburseDates.findIndex((row) => row.id === currentRow.id);
                            setPreviousDate(currentIndex === disburseDates.length - 1 ? '' : disburseDates[currentIndex + 1]?.Value)
                        }}
                        localeText={{ noRowsLabel: "No Disbursement Dates Found" }}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 25 } }
                        }}
                        sx={{
                            ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                                "marginTop": "1em",
                                "marginBottom": "1em"
                            },

                            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                outline: "none !important",
                            }, "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                                outline: "none !important",
                            },
                        }}
                    />
                </Box>
                <hr />
                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: '1rem', mt: '1vh' }}>
                    <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={handleGenerateReportClick} disabled={loadingDates}>Generate Report</Button>
                    <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={handleClose} disabled={loadingDates}>Cancel</Button>
                </Box>
                
            </Box>
        </Paper>
    )
}

export default ReportsModal;