import React, { useContext, useReducer } from 'react';
import axios from 'axios';

import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Paper, Button, Box, TextField, LinearProgress } from '@mui/material';

import { toast } from 'react-toastify';

import { apiRoute } from '../../../../../App';
import UserContext from '../../../../../context/UserContext';

const UserForm = (props) => {
    const { handleClose, data, loading, setLoading, modalType, ts, rows, setRows, getRows } = props;

    const { superAdminCheck, userRoleChoices, users } = useContext(UserContext);

    const initialState = {
        name: modalType === 'add' ? '' : data?.name || '',
        email: modalType === 'add' ? '' : data?.email || '',
        password: modalType === 'add' ? '' : '',
        confirmPassword: modalType === 'add' ? '' : '',
        roles: modalType === 'add' ? [] : users?.filter((item) => item.id === data?.id)[0]?.roles || [],
        errors: {}
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const handleBlur = (field, value) => {
        let error = null;
        switch (field) {
            case 'name':
                if (value.trim() === '') {
                    error = 'User Name Required';
                } else if (value.length < 1 || value.length > 255) {
                    error = 'Name must be between 1 and 255 characters';
                }
                break;
            case 'email':
                if (value.trim() === '') {
                    error = 'Email Required';
                } else if (value.length > 255) {
                    error = 'Email is too long';
                } else if (!/\S+@\S+\.\S+/.test(value)) {
                    error = 'Invalid email format';
                }
                break;
            case 'password':
                if (modalType === 'add') {
                    if (value.trim() === '') {
                        error = 'Password Required';
                    } else if (value.length < 8 || value.length > 25) {
                        error = 'Password must be between 8 and 25 characters';
                    } else if (!/[a-z]/.test(value)) {
                        error = 'Password must contain at least one lowercase letter';
                    } else if (!/[A-Z]/.test(value)) {
                        error = 'Password must contain at least one uppercase letter';
                    } else if (!/\d/.test(value)) {
                        error = 'Password must contain at least one digit';
                    } else if (!/[!@#$%^&*]/.test(value)) {
                        error = 'Password must contain at least one special character (!@#$%^&*)';
                    } else if (/\s/.test(value)) {
                        error = 'Password cannot contain whitespace';
                    }
                }
                break;
            case 'confirmPassword':
                const password = state.password;
                if (value !== password) {
                    error = 'Confirm password must match Password';
                }
                break;
            case 'roles':
                if (!value || value.length === 0) {
                    error = 'At least one role must be selected';
                }
                break;
            default:
                break;
        }

        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const handleRoleChange = (value) => {
        const updatedRoles = state?.roles?.includes(value)
            ? state?.roles?.filter((role) => role !== value)
            : [value];
        handleFieldChange('roles', updatedRoles);
    };

    const userRolesArray = superAdminCheck ? userRoleChoices : userRoleChoices.filter((role) => role.code !== "SUPER_ADMIN");

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (modalType === 'add') {
            const user = {
                name: state?.name,
                email: state?.email,
                password: state?.password,
                confirmPassword: state?.confirmPassword,
                roles: state?.roles,
            }
            axios.post(`${apiRoute}/api/users/addUser`, user)
                .then((res) => {
                    setRows([...rows, { ...res.data, id: res.data.pkUserId, roles: state?.roles }])
                    toast.success('User added successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    if (!!err?.response?.data) {
                        toast.error(`${err?.response?.data}`, {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored',
                        });
                    } else {
                        toast.error(`Error ${err?.response?.status} while adding new User!`, {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored',
                        });
                    }
                    console.log(err, 'Error adding user!');
                })
                .finally(() => { setLoading(false); getRows() })
        } else {
            const user = {
                ...data,
                name: state?.name,
                email: state?.email,
                password: state?.password,
                confirmPassword: state?.confirmPassword,
                roles: state?.roles,
            }
            axios.put(`${apiRoute}/api/users/${data.id}`, user)
                .then((res) => {
                    const updatedRows = rows?.map(row => {
                        if (row.id === data.id) {
                            return { ...row, ...user };
                        }
                        return row;
                    });
                    setRows(updatedRows);
                    toast.success('User updated successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    if (!!err?.response?.data) {
                        toast.error(`${err?.response?.data}`, {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored',
                        });
                    } else {
                        toast.error(`Error ${err?.response?.status} while updating new User!`, {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored',
                        });
                    }
                    console.log(err, 'Error updating user');
                })
                .finally(() => { setLoading(false); getRows() })
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Paper elevation={10}>
                    <Paper sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                        <h1>
                            <strong>
                                {
                                    modalType === 'add' ? 'Add User' : 'Edit User'
                                }
                            </strong>
                        </h1>
                    </Paper>
                    <Box p={1}>
                        <Box width={1} flexWrap="wrap" display="flex">
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="name"
                                    label="User Name"
                                    name="name"
                                    value={state.name}
                                    onChange={(e) => {
                                        handleFieldChange('name', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('name', e.target.value) }}
                                    helperText={state?.errors?.name}
                                    error={!!state?.errors?.name}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="email"
                                    label="User Email"
                                    name="email"
                                    type="email"
                                    value={state.email}
                                    onChange={(e) => {
                                        handleFieldChange('email', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('email', e.target.value) }}
                                    helperText={state?.errors?.email}
                                    error={!!state?.errors?.email}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required={modalType === 'add'}
                                    sx={ts}
                                    id="password"
                                    label={modalType === 'add' ? 'Password' : 'New Password'}
                                    name="password"
                                    type="password"
                                    value={state.password}
                                    onChange={(e) => {
                                        handleFieldChange('password', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('password', e.target.value) }}
                                    helperText={state?.errors?.password}
                                    error={!!state?.errors?.password}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required={modalType === 'add'}
                                    sx={ts}
                                    id="confirmPassword"
                                    label={modalType === 'add' ? 'Confirm Password' : 'Confirm New Password'}
                                    name="confirmPassword"
                                    type="password"
                                    value={state.confirmPassword}
                                    onChange={(e) => {
                                        handleFieldChange('confirmPassword', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('confirmPassword', e.target.value) }}
                                    helperText={state?.errors?.type}
                                    error={!!state?.errors?.type}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={1} mt={3} pr={3}>
                                <FormControl component="fieldset" variant="standard">
                                    <FormLabel component="legend">User Roles</FormLabel>
                                    <FormGroup row>
                                        {userRolesArray?.map((role) => {
                                            const { code } = role;
                                            return (
                                                <FormControlLabel
                                                    key={code}
                                                    control={
                                                        <Checkbox
                                                            checked={state?.roles.includes(code)}
                                                            onChange={() => handleRoleChange(code)}
                                                            name="roles"
                                                            value={role}
                                                        />
                                                    }
                                                    label={code.replace('COLLECT_', '')}
                                                />
                                            )
                                        })}
                                    </FormGroup>
                                    {state?.errors?.roles && (
                                        <FormHelperText error style={{ marginLeft: '12px' }}>
                                            {state.errors.roles}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="right" gap={2} p={1}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            type="submit"
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Paper>
            </form>
            {
                loading && (
                    <LinearProgress sx={{ width: '99.5%', m: '0 auto' }} />
                )
            }
        </>
    );
};

export default UserForm;
