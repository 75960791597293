import React, { useState, useContext } from 'react';
import axios from 'axios';

import { Box, Button, TextField, Paper, LinearProgress } from '@mui/material';

import { toast } from 'react-toastify';

import { apiRoute } from '../../../App';
import { formatDate } from '../../../Utils';
import { DistributionCheckContext } from '../../../context/DistributionCheckContext';

const DistributionCheckModal = (props) => {
    const { handleClose, setLoadingPrint, selectedCheck, ts, loadingPrint } = props;

    const { loadData } = useContext(DistributionCheckContext);

    const [checkNum, setCheckNum] = useState('');

    const handlePrint = (id) => {
        setLoadingPrint(true);
        axios.put(`${apiRoute}/api/ffchecktbls/void/${id}`)
            .then((res) => {
                const check = {
                    ...res.data,
                    checkDate: formatDate(new Date())
                };
                axios.get(`${apiRoute}/api/PrintDisbursementChecks/Reprint?checkId=${id}&checkDate=${check.checkDate}&checkNum=${checkNum}`,
                    { responseType: 'arraybuffer' })
                    .then((response) => {
                        const arrayBufferView = new Uint8Array(response.data);
                        const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
                        const dataUrl = URL.createObjectURL(blob);
                        window.open(dataUrl, '_blank');
                        loadData();
                        handleClose();
                        setLoadingPrint(false);
                    })
                    .catch(error => {
                        console.error('Error fetching report from front end:', error);
                    })
            })
            .catch((err) => {
                toast.error(`Error ${err.response.status} while trying to reprint check`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
    };

    return (
        <>
            <Paper elevation={10}>
                <Paper sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                    <h1 style={{ fontWeight: 'bold' }}>Print New Check</h1>
                </Paper>
                <Box sx={{ textAlign: 'center' }}>
                    <h6 style={{ fontWeight: 'bold' }}>Please input the check number for the new check being printed.</h6>
                    <Box mt={2} mb={1.5}>
                        <TextField
                            fullWidth
                            sx={{ ...ts, width: '50%' }}
                            id="checkNum"
                            label="New Check Number"
                            value={checkNum}
                            onChange={(e) => setCheckNum(e.target.value)}
                            variant="outlined"
                        />
                    </Box>
                </Box>
                <hr />
                <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', pb: '1vh', mr: '1rem' }}>
                    <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={() => handlePrint(selectedCheck.pkFfCheckId)} disabled={!checkNum}>Print Check</Button>
                    <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Close</Button>
                </Box>
            </Paper>
            {
                loadingPrint && <LinearProgress sx={{ width: '99.5%', m: '0 auto' }} />
            }
        </>
    )
}

export default DistributionCheckModal;