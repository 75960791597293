import React, { useContext, useEffect, useReducer, useState } from 'react';
import axios from 'axios';

import { Box, Button, Checkbox, FormControlLabel, MenuItem, TextField, Paper, LinearProgress } from '@mui/material';

import { toast } from 'react-toastify';
import { apiRoute } from '../../../../../App';
import { UserContext } from '../../../../../context/UserContext';


const GlAccountsForm = (props) => {
    const { handleClose, data, loading, setLoading, modalType, ts, rows, setRows, getRows } = props;

    const [disburseRules, setDisburseRules] = useState([]);

    const { banks } = useContext(UserContext);

    const initialState = {
        description: modalType === 'add' ? null : data?.description,
        glAccountType: modalType === 'add' ? null : data?.glAccountType,
        accountNumber: modalType === 'add' ? null : data?.accountNumber,
        fkDisburseRuleId: modalType === 'add' ? null : data?.fkDisburseRuleId,
        type: modalType === 'add' ? null : data?.type,
        fundNumber: modalType === 'add' ? null : data?.fundNumber,
        isActive: modalType === 'add' ? "Y" : data?.isActive,
        createdBy: modalType === 'add' ? null : data?.createdBy,
        fkBankId: modalType === 'add' ? null : data?.fkBankId 
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;

        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        if (modalType === 'add') {
            const account = {
                Description: state?.description,
                GlAccountType: state?.glAccountType,
                AccountNumber: state?.accountNumber,
                FkDisburseRuleId: state?.fkDisburseRuleId,
                Type: state?.type,
                FundNumber: state?.fundNumber,
                isActive: state?.isActive,
                FkBankId: state?.fkBankId
            }
            axios.post(`${apiRoute}/api/GlAccountsTbls`, account)
                .then(({ data }) => {
                    setRows([...rows, { ...data, id: data.pkAccountId }])
                    toast.success('Account added successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    toast.error(`Error ${err?.response?.status} while creating GL Account Record`, {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    })
                })
                .finally(() => { setLoading(false); getRows(); })
                

        } else {
            const account = {
                ...data,
                Description: state?.description,
                GlAccountType: state?.glAccountType,
                AccountNumber: state?.accountNumber,
                FkDisburseRuleId: state?.fkDisburseRuleId,
                Type: state?.Type,
                FundNumber: state?.fundNumber,
                isActive: state?.isActive,
                FkBankId: state?.fkBankId
            }
            axios.put(`${apiRoute}/api/GlAccountsTbls/${account.pkAccountId}`, account)
                .then(({ data }) => {
                    const updatedRows = rows.map(row => {
                        if (row.id === data.id) {
                            return { ...row, ...data };
                        }
                        return row;
                    });
                    setRows(updatedRows);
                    toast.success('Agency updated successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    toast.error(`Error ${err?.response?.status} while updating GL Bank!`, {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    })
                })
                .finally(() => { setLoading(false); getRows() })
        }
    }

    useEffect(() => {
        axios.get(`${apiRoute}/api/DisburseRuleTbls`)
            .then(({ data }) => { setDisburseRules(data); })
            .catch((err) => { console.log('error getting disburse rules', err) })
    }, [])

    return (
        <>
            <Paper elevation={10}>
                <Paper sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                    <h1>
                        <strong>{modalType === 'add' ? 'Add GL Account' : 'Edit GL Account'}</strong>
                    </h1>
                </Paper>
                <form onSubmit={handleSubmit}>
                    <Box p={1}>
                        <Box width={1} flexWrap="wrap" display="flex">
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="description"
                                    label="Description"
                                    name="description"
                                    value={state.description}
                                    onChange={(e) => {
                                        handleFieldChange('description', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="glAccountType"
                                    label="GL Account Type"
                                    name="glAccountType"
                                    value={state.glAccountType}
                                    onChange={(e) => {
                                        handleFieldChange('glAccountType', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="accountNumber"
                                    label="GL Account Number"
                                    name="accountNumber"
                                    value={state.accountNumber}
                                    onChange={(e) => {
                                        handleFieldChange('accountNumber', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    select
                                    id="fkDisburseRuleId"
                                    label="Charge Type"
                                    name="fkDisburseRuleId"
                                    value={state.fkDisburseRuleId}
                                    onChange={(e) => {
                                        handleFieldChange('fkDisburseRuleId', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                >
                                    {
                                        disburseRules.map((rule) => {
                                            return (
                                                <MenuItem
                                                    key={rule.pkDisburseRuleId}
                                                    value={rule.pkDisburseRuleId}
                                                >
                                                    { rule.description }
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="type"
                                    label="Case Type"
                                    name="type"
                                    value={state.type}
                                    onChange={(e) => {
                                        handleFieldChange('type', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="fundNumber"
                                    label="Fund Number"
                                    name="fundNumber"
                                    value={state.fundNumber}
                                    onChange={(e) => {
                                        handleFieldChange('fundNumber', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    select
                                    id="fkBankId"
                                    label="Bank"
                                    name="fkBankId"
                                    value={state.fkBankId}
                                    onChange={(e) => {
                                        handleFieldChange('fkBankId', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                >
                                    { 
                                        banks.map((bank) => {
                                            return (
                                                <MenuItem
                                                    key={bank.pkBankId}
                                                    value={bank.pkBankId}
                                                >
                                                    { bank.name }
                                                </MenuItem>

                                            )
                                        })
                                    }
                                </TextField>
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <FormControlLabel
                                    label="Is Active"
                                    control={
                                        <Checkbox
                                            fullWidth
                                            sx={ts}
                                            id="isActive"
                                            label="Is Active"
                                            name="isActive"
                                            checked={state.isActive === "Y"}
                                            onChange={(e) => {
                                                handleFieldChange('isActive', state.isActive === "N" ? "Y" : "N");
                                            }}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    }
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="right" gap={2} p={1}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            type="submit"
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Paper>
        </>
    );
}

export default GlAccountsForm; 