 import React, { useReducer } from 'react';
import axios from 'axios';

import { Box, Button, TextField, Paper, MenuItem, LinearProgress } from '@mui/material';

import { toast } from 'react-toastify';

import { apiRoute } from '../../../../../App';
import { stateOptions } from '../../../../../Utils';

const EntityForm = (props) => {
    const { handleClose, data, modalType, setLoading, loading, ts, rows, setRows, getRows } = props;

    // County Types Dropdown List
    const parishCountyTypeOptions = [
        {
            label: '',
            value: null,
        },
        {
            label: 'FED',
            value: 'FED',
        },
        {
            label: 'PARB',
            value: 'PARB',
        },
    ];

    const initialState = {
        entityName: modalType === 'add' ? null : data.entityName,
        entityType: modalType === 'add' ? null : data.entityType,
        streetNumber: modalType === 'add' ? null : data.streetNumber,
        streetName: modalType === 'add' ? null : data.streetName,
        city: modalType === 'add' ? null : data.city,
        state: modalType === 'add' ? null : data.state,
        zip: modalType === 'add' ? null : data.zip,
        phone: modalType === 'add' ? null : data.phone,
        email: modalType === 'add' ? null : data.email,
        sheriffName: modalType === 'add' ? null : data.sheriffName,
        paymentUrl: modalType === 'add' ? null : data.paymentUrl,
        schoolFormula: modalType === 'add' ? null : data.schoolFormula,
        schoolAmount: modalType === 'add' ? null : data.schoolAmount,
        constructionFormula: modalType === 'add' ? null : data.constructionFormula,
        constructionAmount: modalType === 'add' ? null : data.constructionAmount,
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const handleBlur = (field, value) => {
        let error = null;
        switch (field) {
            case 'entityName':
                if (!value) {
                    error = 'Description is required';
                }
                break;
            default:
                break;
        }
        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (modalType === 'add') {
            const entity = {
                EntityName: state.entityName,
                EntityType: state.entityType,
                StreetNumber: state.streetNumber,
                StreetName: state.streetName,
                City: state.city,
                State: state.state,
                Zip: state.zip,
                Phone: state.phone,
                Email: state.email,
                SheriffName: state.sheriffName,
                PaymentUrl: state.paymentUrl,
                SchoolFormula: state.schoolFormula,
                SchoolAmount: state.schoolAmount,
                ConstructionFormula: state.constructionFormula,
                ConstructionAmount: state.constructionAmount,
            }
            axios.post(`${apiRoute}/api/Entities`, entity)
                .then((res) => {
                    setRows([...rows, { ...res.data, id: res.data.id }])
                    toast.success('Entity added successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    toast.error(`Error ${err?.response?.status} while creating entity!`, {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    console.log(err, 'Error adding entity!');
                })
                .finally(() => { setLoading(false); getRows() })
        } else {
            const entity = {
                ...data,
                EntityName: state.entityName,
                EntityType: state.entityType,
                StreetNumber: state.streetNumber,
                StreetName: state.streetName,
                City: state.city,
                State: state.state,
                Zip: state.zip,
                Phone: state.phone,
                Email: state.email,
                SheriffName: state.sheriffName,
                PaymentUrl: state.paymentUrl,
                SchoolFormula: state.schoolFormula,
                SchoolAmount: state.schoolAmount,
                ConstructionFormula: state.constructionFormula,
                ConstructionAmount: state.constructionAmount,
            }
            axios.put(`${apiRoute}/api/Entities/${data.id}`, entity)
                .then((res) => {
                    const updatedRows = rows.map(row => {
                        if (row.id === data.id) {
                            return { ...row, ...res.data };
                        }
                        return row;
                    });
                    setRows(updatedRows);
                    toast.success('Entity updated successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    toast.error(`Error ${err?.response?.status} while updating entity!`, {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    console.log(err, 'Error updating entity');
                })
                .finally(() => { setLoading(false); getRows() })
        }
    }

    return (
        <>
            <Paper elevation={10}>
                <Paper sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                    <h1>
                        <strong>
                            {modalType === 'add' ? 'Add Entity' : 'Edit Entity'}
                        </strong>
                    </h1>
                </Paper>
                <form onSubmit={handleSubmit}>
                    <Box p={1}>
                        <Box width={1} flexWrap="wrap" display="flex">
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="entityName"
                                    label="Entity Name"
                                    name="entityName"
                                    value={state.entityName}
                                    onChange={(e) => {
                                        handleFieldChange('entityName', e.target.value);
                                    }}
                                    onBlur={(e) => handleBlur('entityName', e.target.value)}
                                    helperText={state?.errors?.entityName}
                                    error={!!state?.errors?.entityName}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="entityType"
                                    label="Entity Type"
                                    name="entityType"
                                    value={state.entityType}
                                    onChange={(e) => {
                                        handleFieldChange('entityType', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 3]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="streetNumber"
                                    label="Street #"
                                    name="streetNumber"
                                    value={state.streetNumber}
                                    onChange={(e) => {
                                        handleFieldChange('streetNumber', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 3]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="streetName"
                                    label="Street Name"
                                    name="streetName"
                                    value={state.streetName}
                                    onChange={(e) => {
                                        handleFieldChange('streetName', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 3]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="city"
                                    label="City"
                                    name="city"
                                    value={state.city}
                                    onChange={(e) => {
                                        handleFieldChange('city', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 3]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="state"
                                    label="State"
                                    name="state"
                                    value={state.state}
                                    onChange={(e) => {
                                        handleFieldChange('state', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                >
                                    {
                                        stateOptions?.map(({ value, label }) => (
                                            <MenuItem key={label} value={value}>{label}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Box>
                            <Box width={[1, 1, 1 / 3]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="zip"
                                    label="Zip Code"
                                    name="zip"
                                    value={state.zip}
                                    onChange={(e) => {
                                        handleFieldChange('zip', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 3]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="phone"
                                    label="Phone"
                                    name="phone"
                                    value={state.phone}
                                    onChange={(e) => {
                                        handleFieldChange('phone', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 3]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="email"
                                    label="Email"
                                    name="email"
                                    value={state.email}
                                    onChange={(e) => {
                                        handleFieldChange('email', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 3]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="sheriffName"
                                    label="Sheriff Name"
                                    name="sheriffName"
                                    value={state.sheriffName}
                                    onChange={(e) => {
                                        handleFieldChange('sheriffName', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 3]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="paymentUrl"
                                    label="Payment Url"
                                    name="paymentUrl"
                                    value={state.paymentUrl}
                                    onChange={(e) => {
                                        handleFieldChange('paymentUrl', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 4]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="schoolFormula"
                                    label="School Formula"
                                    name="schoolFormula"
                                    value={state.schoolFormula}
                                    onChange={(e) => {
                                        handleFieldChange('schoolFormula', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 4]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="schoolAmount"
                                    label="School Amount"
                                    name="schoolAmount"
                                    value={state.schoolAmount}
                                    onChange={(e) => {
                                        handleFieldChange('schoolAmount', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 4]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="constructionFormula"
                                    label="Construction Formula"
                                    name="constructionFormula"
                                    value={state.constructionFormula}
                                    onChange={(e) => {
                                        handleFieldChange('constructionFormula', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 4]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="constructionAmount"
                                    label="Construction Amount"
                                    name="constructionAmount"
                                    value={state.constructionAmount}
                                    onChange={(e) => {
                                        handleFieldChange('constructionAmount', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="right" gap={2} p={1}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            type="submit"
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Paper>
            {
                loading && (
                    <LinearProgress sx={{ width: '99.5%', m: '0 auto' }} />
                )
            }
        </>
    );
};

export default EntityForm;
