import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';

import { Box, Button, Checkbox, FormControlLabel, Paper } from '@mui/material';

import ViolationsViewTable from '../../tables/views/violationsTab/ViolationsViewTable';

import { CaseContext } from '../../../context/CaseContext';
import { apiRoute } from '../../../App';

const ViolationsBox = (props) => {
    const {
        getViolationFeeFines,
        handleOpen,
        setCaseType,
        violationId,
        updateBalanceRows,
        updateCaseBalance,
        setDescription,
        setViolationData,
        violationData,
        setViolationView,
        violationView,
        setFeeRules,
        state,
        handleFieldChange,
        comments,
        setComments,
        vioTransactionTbl,
        setVioTransactionTbl,
    } = props;

    const { citationView, violationRows } = useContext(CaseContext);

    const [schoolZone, setSchoolZone] = useState(state?.schoolZone === 'Y' ? true : false);
    const [constructionZone, setConstructionZone] = useState(state?.constructionZone === 'Y' ? true : false);
    const [deafBlindZone, setDeafBlindZone] = useState(false);
    const [loading, setLoading] = useState(false);

    const [speedOrTrafChargesCheck, setSpeedOrTrafChargesCheck] = useState(false);

    useEffect(() => {
        setSpeedOrTrafChargesCheck(violationRows.some(({ chargeType, description }) => {
            return chargeType === "SPEED" || chargeType === "TRAF" || description.toLowerCase().includes('speed') || description.toLowerCase().includes('traffic');
        }));
    }, [violationRows]);

    const addZone = async (zone) => {
        try {
            await setLoading(true);

            let value;

            if (zone === 'school') {
                if (state?.schoolZone === 'N' || state?.schoolZone === '') {
                    handleFieldChange('schoolZone', 'Y');
                } else {
                    handleFieldChange('schoolZone', 'N');
                }
                value = !schoolZone;
                setSchoolZone(value);
            } else {
                if (state?.constructionZone === 'N' || state?.constructionZone === '') {
                    handleFieldChange('constructionZone', 'Y');
                } else {
                    handleFieldChange('constructionZone', 'N');
                }
                value = !constructionZone;
                setConstructionZone(value);
            }

            let updatedColumn = value ? 'Y' : 'N';

            const speedOrTrafChargesArray = violationRows.filter(vio => {
                return vio.chargeType === "SPEED" || vio.chargeType === "TRAF" || vio.description.toLowerCase().includes('speed') || vio.description.toLowerCase().includes('traffic');
            });

            await Promise.all(speedOrTrafChargesArray?.map(async ({ pkViolationId }) => {
                await axios.put(`${apiRoute}/api/citationtbls/zone/${zone}/${pkViolationId}/${citationView.pkCitationId}/${updatedColumn}`);
                await updateCaseBalance();
                await updateBalanceRows();
            }));
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Paper sx={{ width: '100%', mb: '1vh' }} elevation={10}>
            <Paper sx={{ backgroundColor: 'steelblue', color: 'white', height: '3vh', m: 'auto', p: '.5vh' }}>
                <h6 style={{ fontWeight: 'bold', fontSize: 'inherit' }}>Violations</h6>
            </Paper>
            <Box sx={{ mt: '1vh' }}>
                <Paper
                    sx={{
                        width: '99%',
                        margin: '0 auto',
                        minHeight: '10vh',
                        maxHeight: "30.5vh",
                    }}
                    elevation={10}
                >
                    <ViolationsViewTable
                        getViolationFeeFines={getViolationFeeFines}
                        setCaseType={setCaseType}
                        violationId={violationId}
                        updateCaseBalance={updateCaseBalance}
                        updateBalanceRows={updateBalanceRows}
                        handleOpen={handleOpen}
                        setDescription={setDescription}
                        setViolationView={setViolationView}
                        setViolationData={setViolationData}
                        violationView={violationView}
                        violationData={violationData}
                        setFeeRules={setFeeRules}
                        comments={comments}
                        setComments={setComments}
                        vioTransactionTbl={vioTransactionTbl}
                        setVioTransactionTbl={setVioTransactionTbl}
                    />
                </Paper>
                <Box sx={{
                    width: '99%',
                    height: '7vh',
                    margin: '0 auto',
                    display: 'flex',
                    gap: '1rem'
                }}>
                    <Button
                        onClick={() => {
                            handleOpen('addViolation', '50%', false)
                        }}
                        variant='contained'
                        sx={{ backgroundColor: 'steelblue', color: 'white', height: '50%', m: 'auto 0' }}
                    >
                        + Add Violation
                    </Button>
                    {
                        speedOrTrafChargesCheck && (
                            <Box sx={{
                                width: '60%',
                                ml: '0.5rem',
                                display: 'flex',
                                gap: '1rem',
                                alignItems: 'center'
                            }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onClick={() => addZone("school")}
                                            checked={schoolZone}
                                            disabled={loading}
                                        />}
                                    label="School Zone"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onClick={() => addZone("construction")}
                                            checked={constructionZone}
                                            disabled={loading}
                                        />}
                                    label="Construction Zone"
                                />
                                {/*<FormControlLabel*/}
                                {/*    control={*/}
                                {/*        <Checkbox*/}
                                {/*            onClick={() => addZone("deafBlind")}*/}
                                {/*            checked={deafBlindZone}*/}
                                {/*            disabled={loading}*/}
                                {/*        />}*/}
                                {/*    label="Deaf/Blind Zone"*/}
                                {/*/>*/}
                            </Box>
                        )
                    }
                </Box>
            </Box>
        </Paper>
    )
}

export default ViolationsBox;