import React, { useReducer } from 'react';
import axios from 'axios';

import { Grid, Box, Button, TextField, Paper, LinearProgress } from '@mui/material';

import { toast } from 'react-toastify';

import { apiRoute } from '../../../../../App';

const SyscodeForm = (props) => {
    const { handleClose, data, loading, setLoading, modalType, ts, rows, setRows, getRows } = props;

    const initialState = {
        code: modalType === 'add' ? null : data?.code,
        description: modalType === 'add' ? null : data?.description,
        type: modalType === 'add' ? null : data?.type,
        defaultValue: modalType === 'add' ? null : data?.defaultValue,
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const handleBlur = (field, value) => {
        let error = null;
        switch (field) {
            case 'description':
                if (!value) {
                    error = 'Description is required';
                }
                break;
            case 'type':
                if (!value) {
                    error = 'Type is required'
                }
                break;
            case 'code':
                if (!value) {
                    error = 'Code is required';
                }
                break;
            case 'defaultValue':
                if (!value) {
                    error = 'Default value is required'
                }
                break;
            default:
                break;
        }
        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (modalType === 'add') {
            const sysCode = {
                code: state?.code,
                description: state?.description,
                type: state?.type,
                defaultValue: state?.defaultValue,
            }
            axios.post(`${apiRoute}/api/SyscodeTbls`, sysCode)
                .then((res) => {
                    setRows([...rows, {...res.data, id: res.data.pkSyscodeId}])
                    toast.success('System Code added successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    toast.error(`Error ${err?.response?.status} while adding system code!`, {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    console.log(err, 'Error adding sysCode!');
                })
                .finally(() => { setLoading(false); getRows() })
        } else {
            const sysCode = {
                ...data,
                code: state?.code,
                description: state?.description,
                type: state?.type,
                defaultValue: state?.defaultValue,
            }
            axios.put(`${apiRoute}/api/SyscodeTbls/${data.id}`, sysCode)
                .then((res) => {
                    const updatedRows = rows?.map(row => {
                        if (row.id === data.id) {
                            return { ...row, ...res.data };
                        }
                        return row;
                    });
                    setRows(updatedRows);
                    toast.success('System Code updated successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    toast.error(`Error ${err?.response?.status} while updating system code!`, {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    console.log(err, 'Error updating SysCode');
                })
                .finally(() => { setLoading(false); getRows() })
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Paper elevation={10}>
                    <Paper sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                        <h1>
                            <strong>
                                {
                                    modalType === 'add' ? 'Add System Code Data' : 'Edit System Code Data'
                                }
                            </strong>
                        </h1>
                    </Paper>
                    <Box p={1}>
                        <Box width={1} flexWrap="wrap" display="flex">
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={3} mt={.75}>
                                    <TextField
                                        fullWidth
                                        required
                                        sx={ts}
                                        id="type"
                                        label="Type"
                                        name="type"
                                        value={state.type}
                                        onChange={(e) => {
                                            handleFieldChange('type', e.target.value);
                                        }}
                                        onBlur={(e) => { handleBlur('type', e.target.value) }}
                                        helperText={state?.errors?.type}
                                        error={!!state?.errors?.type}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ max: 9999 }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} mt={.75}>
                                    <TextField
                                        fullWidth
                                        required
                                        sx={ts}
                                        id="code"
                                        label="Code"
                                        name="code"
                                        value={state.code}
                                        onChange={(e) => {
                                            handleFieldChange('code', e.target.value);
                                        }}
                                        onBlur={(e) => { handleBlur('code', e.target.value) }}
                                        helperText={state?.errors?.code}
                                        error={!!state?.errors?.code}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} mt={.75}>
                                    <TextField
                                        fullWidth required
                                        sx={ts}
                                        id="defaultValue"
                                        label="Default Value"
                                        name="defaultValue"
                                        value={state.defaultValue}
                                        onChange={(e) => {
                                            handleFieldChange('defaultValue', e.target.value);
                                        }}
                                        onBlur={(e) => { handleBlur('defaultValue', e.target.value) }}
                                        helperText={state?.errors?.defaultValue}
                                        error={!!state?.errors?.defaultValue}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} mt={.75}>
                                    <TextField
                                        fullWidth
                                        required
                                        sx={ts}
                                        id="description"
                                        label="Description"
                                        multiline
                                        rows={16}
                                        name="description"
                                        value={state.description}
                                        onChange={(e) => {
                                            handleFieldChange('description', e.target.value);
                                        }}
                                        onBlur={(e) => { handleBlur('description', e.target.value) }}
                                        helperText={state?.errors?.description}
                                        error={!!state?.errors?.description}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="right" gap={2} p={1}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            type="submit"
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Paper>
            </form>
            {
                loading && (
                    <LinearProgress sx={{ width: '99.5%', m: '0 auto' }} />
                )
            }
        </>
    );
};

export default SyscodeForm;
