import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    Paper,
    TextField,
} from '@mui/material';

import { MdExpandMore } from "react-icons/md";

import { CaseContext } from '../../context/CaseContext';
import { apiRoute } from '../../App';
import { NewVehicle } from '../../components/jsx/NewVehicle';
import { toast, ToastContainer } from 'react-toastify';

const VehicleAccidentView = (props) => {
    const {
        state,
        handleFieldChange,
        handleBlur,
        ts,
        caseNumber,
    } = props;

    const {
        vehicleMakes,
        vehicleTypes,
        vehicleStyles,
        vehicleColors,
        states,
    } = useContext(CaseContext);

    const otherVehicleDefaultObj = {
        vehicleYear: '',
        vehicleMake: '',
        vehicleModel: '',
        vehicleType: '',
        vehicleStyle: '',
        vehicleColor: '',
        vehicleLicenseNumber: '',
        vehicleLicenseState: '',
        vehicleLicenseYear: '',
        hazmatVehicle: '',
        commercialVehicle: '',
        propertyDamage: '',
        personalInjury: '',
        fatal: '',
        changes: false,
    };

    const [otherVehicles, setOtherVehicles] = useState([otherVehicleDefaultObj]);

    const updateOtherVehicle = (field, index, value) => {
        setOtherVehicles((others) => {
            const updatedVehicles = [...others];
            updatedVehicles[index] = {
                ...updatedVehicles[index],
                changes: true,
                [field]: value
            };
            return updatedVehicles;
        });
    };

    const saveOtherVehicle = async (i) => {

        const lastIndex = otherVehicles.length - 1;
        const index = i == null || i === undefined ? lastIndex : i;

        const otherVehicle = {
            VehicleYear: otherVehicles[index].vehicleYear,
            VehicleMake: otherVehicles[index].vehicleMake,
            VehicleModel: otherVehicles[index].vehicleModel,
            VehicleType: otherVehicles[index].vehicleType,
            VehicleColor: otherVehicles[index].vehicleColor,
            VehicleLicenseNumber: otherVehicles[index].vehicleLicenseNumber,
            VehicleLicenseState: otherVehicles[index].vehicleLicenseState,
            VehicleLicenseYear: otherVehicles[index].vehicleLicenseYear,
            HazmatVehicle: otherVehicles[index].hazmatVehicle,
            CommercialVehicle: otherVehicles[index].commercialVehicle,
            PersonalInjury: otherVehicles[index].personalInjury,
            Fatal: otherVehicles[index].fatal,
            PropertyDamage: otherVehicles[index].propertyDamage,
        }
        try {
            await axios.post(`${apiRoute}/api/vehicleTbls/${caseNumber}`, otherVehicle)
            toast.success("Other Vehicle Saved Successfully", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            otherVehicles[index].changes = false;
            getOtherVehicles();
        }
        catch (e) {
            toast.error(`Error Processing Other Vehicle: ${e}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };

    const getOtherVehicles = async () => {
        try {
            const response = await axios.get(`${apiRoute}/api/vehicleTbls/${caseNumber}`);
            const responseData = response.data;

            const updatedData = responseData.map(item => ({ ...item, changes: false }));

            // Update otherVehicles state, avoiding duplicates
            setOtherVehicles(prevOthers => {
                const uniqueData = new Set([...updatedData, otherVehicleDefaultObj]);
                return [...uniqueData];
            });
        } catch (error) {
            console.log('Error from getOtherVehicles:', error);
        }
    };

    const editOtherVehicle = async (vehicle, index) => {
        try {
            await axios.put(`${apiRoute}/api/vehicleTbls/${vehicle.pkVehicleId}`, vehicle)
            toast.success("Other Vehicle Edited Successfully", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            otherVehicles[index].changes = false;
            getOtherVehicles();
        }
        catch (e) {
            toast.error(`Error Updating Other Vehicle: ${e}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            console.log('Edit Other Vehicle Error: ', e)
        }
    }

    useEffect(() => {
        if (caseNumber) getOtherVehicles();
    }, [caseNumber])

    return (
        <>
            <Accordion
                defaultExpanded
                sx={{ background: 'transparent' }}
            >
                <AccordionSummary expandIcon={<MdExpandMore />}
                    sx={{ backgroundColor: 'steelblue', color: 'white' }}
                >
                    Defendant Vehicle Information
                </AccordionSummary>
                <AccordionDetails >
                    <Box sx={{ height: "100%" }}>
                        <Box sx={{ width: "99%", margin: "1vh auto" }}>
                            <Paper sx={{ width: '100%', mb: '2vh', pb: '.25vh' }} elevation={10}>
                                <Paper sx={{ backgroundColor: 'steelblue', color: 'white', height: '3vh', m: 'auto', p: '.5vh' }}>
                                    <h6 style={{ fontWeight: 'bold', fontSize: 'inherit' }}>Vehicle</h6>
                                </Paper>
                                <Box sx={{ width: '97%', margin: '1vh auto' }}>
                                    <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", gap: '1rem' }}>
                                        <Grid item sm={1}>
                                            <TextField
                                                fullWidth
                                                sx={ts}
                                                id='vehicleYear'
                                                label='Year'
                                                name='vehicleYear'
                                                value={state?.vehicleYear}
                                                onChange={(e) => handleFieldChange('vehicleYear', e.target.value)}
                                                onBlur={(e) => handleBlur('vehicleYear', e.target.value)}
                                                helperText={state?.errors?.vehicleYear}
                                                error={!!state?.errors?.vehicleYear}
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 4 }}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item sm={2}>
                                            <TextField
                                                fullWidth
                                                select
                                                sx={ts}
                                                id='vehicleMake'
                                                label='Make'
                                                name='vehicleMake'
                                                value={state?.vehicleMake}
                                                onChange={(e) => handleFieldChange('vehicleMake', e.target.value)}
                                                onBlur={(e) => handleBlur('vehicleMake', e.target.value)}
                                                helperText={state?.errors?.vehicleMake}
                                                error={!!state?.errors?.vehicleMake}
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                SelectProps={{
                                                    MenuProps: {
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: '20em',
                                                            },
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem value={''}>N/A</MenuItem>
                                                {
                                                    vehicleMakes?.map(({ codeValue, codeTitle },) => {
                                                        return <MenuItem key={codeTitle} value={codeValue}>{codeTitle}</MenuItem>
                                                    })
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item sm={2}>
                                            <TextField
                                                fullWidth
                                                sx={ts}
                                                id='vehicleModel'
                                                label='Model'
                                                name='vehicleModel'
                                                value={state?.vehicleModel}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    const isValidInput = /^[a-zA-Z0-9\s]*$/.test(inputValue);

                                                    if (isValidInput) {
                                                        handleFieldChange('vehicleModel', inputValue);
                                                    }
                                                }}
                                                onBlur={(e) => handleBlur('vehicleModel', e.target.value)}
                                                helperText={state?.errors?.vehicleModel}
                                                error={!!state?.errors?.vehicleModel}
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item sm={2}>
                                            <TextField
                                                fullWidth
                                                select
                                                sx={ts}
                                                id='vehicleType'
                                                label='Type'
                                                name='vehicleType'
                                                value={state?.vehicleType}
                                                onChange={(e) => handleFieldChange('vehicleType', e.target.value)}
                                                onBlur={(e) => handleBlur('vehicleType', e.target.value)}
                                                helperText={state?.errors?.vehicleType}
                                                error={!!state?.errors?.vehicleType}
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                SelectProps={{
                                                    MenuProps: {
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: '20em',
                                                            },
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem value={''}>N/a</MenuItem>
                                                {
                                                    vehicleTypes?.map(({ codeValue, codeTitle },) => {
                                                        return <MenuItem key={codeTitle} value={codeValue}>{codeTitle}</MenuItem>
                                                    })
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item sm={2}>
                                            <TextField
                                                fullWidth
                                                select
                                                sx={ts}
                                                id='vehicleStyle'
                                                label='Style'
                                                name='vehicleStyle'
                                                value={state?.vehicleStyle}
                                                onChange={(e) => handleFieldChange('vehicleStyle', e.target.value)}
                                                onBlur={(e) => handleBlur('vehicleStyle', e.target.value)}
                                                helperText={state?.errors?.vehicleStyle}
                                                error={!!state?.errors?.vehicleStyle}
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                SelectProps={{
                                                    MenuProps: {
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: '20em',
                                                            },
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem value={''}>N/A</MenuItem>
                                                {
                                                    vehicleStyles?.map(({ codeValue, codeTitle },) => {
                                                        return <MenuItem key={codeTitle} value={codeValue}>{codeTitle}</MenuItem>
                                                    })
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item sm={2}>
                                            <TextField
                                                fullWidth
                                                select
                                                sx={ts}
                                                id='vehicleColor'
                                                label='Color'
                                                name='vehicleColor'
                                                value={state?.vehicleColor}
                                                onChange={(e) => handleFieldChange('vehicleColor', e.target.value)}
                                                onBlur={(e) => handleBlur('vehicleColor', e.target.value)}
                                                helperText={state?.errors?.vehicleColor}
                                                error={!!state?.errors?.vehicleColor}
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                SelectProps={{
                                                    MenuProps: {
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: '20em',
                                                            },
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem value={''}>N/A</MenuItem>
                                                {
                                                    vehicleColors?.map(({ codeValue, codeTitle },) => {
                                                        return <MenuItem key={codeTitle} value={codeValue}>{codeTitle}</MenuItem>
                                                    })
                                                }
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Paper sx={{ width: '55%', mb: '2vh' }} elevation={10}>
                                    <Paper sx={{ backgroundColor: 'steelblue', color: 'white', height: '3vh', m: 'auto', p: '.5vh' }}>
                                        <h6>
                                            <strong>Vehicle License Plate</strong>
                                        </h6>
                                    </Paper>
                                    <Box sx={{ width: '95%', margin: '1vh auto' }}>
                                        <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", gap: '1rem' }}>
                                            <Grid item sm={5}>
                                                <TextField
                                                    fullWidth
                                                    sx={ts}
                                                    id='vehicleLicenseNumber'
                                                    label='Number'
                                                    name='vehicleLicenseNumber'
                                                    value={state?.vehicleLicenseNumber}
                                                    onChange={(e) => handleFieldChange('vehicleLicenseNumber', e.target.value)}
                                                    onBlur={(e) => handleBlur('vehicleLicenseNumber', e.target.value)}
                                                    helperText={state?.errors?.vehicleLicenseNumber}
                                                    error={!!state?.errors?.vehicleLicenseNumber}
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    sx={ts}
                                                    id='vehicleLicenseState'
                                                    label='State'
                                                    name='vehicleLicenseState'
                                                    value={state?.vehicleLicenseState}
                                                    onChange={(e) => handleFieldChange('vehicleLicenseState', e.target.value)}
                                                    onBlur={(e) => handleBlur('vehicleLicenseState', e.target.value)}
                                                    helperText={state?.errors?.vehicleLicenseState}
                                                    error={!!state?.errors?.vehicleLicenseState}
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="outlined"
                                                    SelectProps={{
                                                        MenuProps: {
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: '20em',
                                                                },
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={''}>N/A</MenuItem>
                                                    {
                                                        states?.map(({ codeValue, codeTitle },) => {
                                                            return <MenuItem key={codeTitle} value={codeValue}>{codeTitle}</MenuItem>
                                                        })
                                                    }
                                                </TextField>
                                            </Grid>
                                            <Grid item sm={.75}>
                                                <TextField
                                                    fullWidth
                                                    placeholder="##"
                                                    sx={ts}
                                                    id='vehicleLicenseYear'
                                                    label='Year'
                                                    name='vehicleLicenseYear'
                                                    value={state?.vehicleLicenseYear}
                                                    onChange={(e) => {
                                                        const inputValue = e.target.value;
                                                        if (/^\d*$/.test(inputValue)) { // Check if input contains only digits
                                                            handleFieldChange('vehicleLicenseYear', inputValue);
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        const inputValue = e.target.value;
                                                        if (/^\d*$/.test(inputValue)) { // Check if input contains only digits
                                                            handleBlur('vehicleLicenseYear', inputValue);
                                                        }
                                                    }}
                                                    helperText={state?.errors?.vehicleLicenseYear}
                                                    error={!!state?.errors?.vehicleLicenseYear}
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="outlined"
                                                    inputProps={{ maxLength: 2 }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                                <Paper sx={{ width: '15%', mb: '2vh' }} elevation={10}>
                                    <Paper sx={{ backgroundColor: 'steelblue', color: 'white', height: '3vh', m: 'auto', p: '.5vh' }}>
                                        <h6>
                                            <strong>Investigation</strong>
                                        </h6>
                                    </Paper>
                                    <Box sx={{ width: '95%', margin: '1vh auto' }}>
                                        <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", gap: '1rem' }}>
                                            <Grid item sm={5}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={state?.hazmatVehicle === 'Y'}
                                                            onChange={(e) => {
                                                                handleFieldChange('hazmatVehicle', e.target.checked ? 'Y' : null)
                                                            }}
                                                        />}
                                                    label="Hazmat"
                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={state?.commercialVehicle === 'Y'}
                                                            onChange={(e) => {
                                                                handleFieldChange('commercialVehicle', e.target.checked ? 'Y' : null)
                                                            }}
                                                        />}
                                                    label="Commercial"
                                                />
                                            </Grid>

                                        </Grid>
                                    </Box>
                                </Paper>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Paper sx={{ width: '50%', mb: '2vh' }} elevation={10}>
                                    <Paper sx={{ backgroundColor: 'steelblue', color: 'white', height: '3vh', m: 'auto', p: '.5vh' }}>
                                        <h6>
                                            <strong>Investigation</strong>
                                        </h6>
                                    </Paper>
                                    <Box sx={{ width: '95%', margin: '1vh auto' }}>
                                        <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", gap: '1rem' }}>
                                            <Grid item sm={5}>
                                                <TextField
                                                    fullWidth
                                                    sx={ts}
                                                    id='itemNum'
                                                    label='Item Number'
                                                    name='itemNum'
                                                    value={state?.itemNum}
                                                    onChange={(e) => handleFieldChange('itemNum', e.target.value)}
                                                    onBlur={(e) => handleBlur('itemNum', e.target.value)}
                                                    helperText={state?.errors?.itemNum}
                                                    error={!!state?.errors?.itemNum}
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item sm={6}>
                                                <TextField
                                                    fullWidth
                                                    sx={ts}
                                                    id='stateControlNum'
                                                    label='State Control Number'
                                                    name='stateControlNum'
                                                    value={state?.stateControlNum}
                                                    onChange={(e) => handleFieldChange('stateControlNum', e.target.value)}
                                                    onBlur={(e) => handleBlur('stateControlNum', e.target.value)}
                                                    helperText={state?.errors?.stateControlNum}
                                                    error={!!state?.errors?.stateControlNum}
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="outlined"
                                                />
                                            </Grid>

                                        </Grid>
                                    </Box>
                                </Paper>
                                <Paper sx={{ width: '30%', mb: '2vh' }} elevation={10}>
                                    <Paper sx={{ backgroundColor: 'steelblue', color: 'white', height: '3vh', m: 'auto', p: '.5vh' }}>
                                        <h6>
                                            <strong>Accident With</strong>
                                        </h6>
                                    </Paper>
                                    <Box sx={{ width: '95%', margin: '1vh auto' }}>
                                        <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", gap: '1rem' }}>
                                            <Grid item sm={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={state?.propertyDamage === 'Y'}
                                                            onChange={(e) => {
                                                                handleFieldChange('propertyDamage', e.target.checked ? 'Y' : null)
                                                            }}
                                                        />}
                                                    label="Property Dmg."
                                                />
                                            </Grid>
                                            <Grid item sm={4}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={state?.personalInjury === 'Y'}
                                                            onChange={(e) => {
                                                                handleFieldChange('personalInjury', e.target.checked ? 'Y' : null)
                                                            }}
                                                        />}
                                                    label="Personal Injury"
                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={state?.fatal === 'Y'}
                                                            onChange={(e) => {
                                                                handleFieldChange('fatal', e.target.checked ? 'Y' : null)
                                                            }}
                                                        />}
                                                    label="Fatal"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Box>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<MdExpandMore />}>
                    Other Vehicle Information
                </AccordionSummary>
                {otherVehicles.map((_, index) => {
                    return (
                        <NewVehicle
                            key={index}
                            index={index}
                            otherVehicles={otherVehicles}
                            updateOtherVehicle={updateOtherVehicle}
                            saveOtherVehicle={saveOtherVehicle}
                            ts={ts}
                            otherVehicleDefaultObj={otherVehicleDefaultObj}
                            editOtherVehicle={editOtherVehicle}
                        />
                    )
                })
                }
            </Accordion>
            
        </>
    )
};

export default VehicleAccidentView;