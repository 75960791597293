import React, { useContext } from 'react';

import { Box, Button, Paper, LinearProgress, TextField } from '@mui/material';
import { ThemeModeContext } from '../../../App';

const FilesModals = (props) => {
    const { modalType, loading, deleteFile, handleClose, file, deleteReason, setDeleteReason } = props;

    const { mode } = useContext(ThemeModeContext);

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === "dark" ? "#4C4E52" : "white",
            color: mode === "dark" ? "white" : "black"
        },
        '& .MuiInputLabel-root': {
            color: mode === "dark" ? "white" : "black",
            textShadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    };

    return (
        <>
            {
                modalType === 'delete' && (
                    <Paper elevation={10}>
                        <Paper sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                            <h1>
                                <strong>Delete File</strong>
                            </h1>
                        </Paper>
                        <Box sx={{ width: '99%', m: '1vh auto' }}>
                            <h3 style={{ textAlign: 'center' }}>You are about to delete {file.fileName} from this case.</h3>
                            <TextField
                                sx={{ ...textFieldStyle, mt: '1vh', pb: '1vh' }}
                                multiline
                                rows={7}
                                value={deleteReason}
                                onChange={(e) => setDeleteReason(e.target.value)}
                                fullWidth
                                label="Delete Reason"
                                variant="outlined"
                                inputProps={{ maxLength: 255 }}
                                InputLabelProps={{ shrink: true }}
                            />
                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', py: '1vh' }}>
                                <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={(e) => deleteFile(file)}>Delete File</Button>
                                <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Cancel</Button>
                            </Box>
                        </Box>
                        {
                            loading && (
                                <>
                                    <LinearProgress />
                                </>
                            )
                        }
                    </Paper>
                )
            }
        </>
    )
};

export default FilesModals;